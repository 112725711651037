import QuestionsProgressBar from "./QuestionsProgressBar";
import SystemDesktopTopBarLayout from "../../../layouts/SystemDesktopTopBarLayout";
import SystemFlashTopBarLogoLayout from "../../../layouts/SystemFlashTopBarLogoLayout";
import SystemFlashLogoImage from "../../../images/SystemFlashLogoImage";
import SystemFlashSelectedGradeBadge from "../../../badges/SystemFlashSelectedGradeBadge";
import SystemDesktopTabletLeaveFlashButton from "../../../buttons/SystemDesktopTabletLeaveFlashButton";
import {FlashLogoTextSize} from "../../../../controllers/SystemFlashLogoTextController";

function QuestionsDesktopTopBar({onLeaveFlashPressed,
                                 selectedGrade,
                                 currentQuestion,
                                 totalQuestion}) {
    // Used in QuestionsStepScreen to display top bar
    // in mobile mode

    // BUILD
    return (
        <SystemDesktopTopBarLayout>
            <SystemFlashTopBarLogoLayout>
                <SystemFlashLogoImage flashLogoSize={FlashLogoTextSize.XSMALL} />
                <SystemFlashSelectedGradeBadge selectedGrade={selectedGrade} />
            </SystemFlashTopBarLogoLayout>
            <QuestionsProgressBar currentQuestion={currentQuestion}
                                  totalQuestion={totalQuestion} />
            <SystemDesktopTabletLeaveFlashButton onLeaveFlashPressed={onLeaveFlashPressed} />
        </SystemDesktopTopBarLayout>
    );
}

// EXPORT
export default QuestionsDesktopTopBar