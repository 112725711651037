import SystemScreenErrorIcon from "../icons/SystemScreenErrorIcon";
import SystemScreenErrorTexts from "../texts/SystemScreenErrorTexts";
import {isNullOrUndefined} from "../../services/Validator";

function SystemScreenErrorContent({title, subtitle}) {
    // Used through the app to display screen error
    // content in both desktop and mobile modes

    // BUILD
    if(isNullOrUndefined(title) && isNullOrUndefined(subtitle))
        return null;
    return (
        <div className={"w-full flex flex-col justify-start items-center space-y-16"}>
            <SystemScreenErrorIcon />
            <SystemScreenErrorTexts title={title}
                                    subtitle={subtitle} />
        </div>
    );
}

// EXPORT
export default SystemScreenErrorContent