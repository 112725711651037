import {isNullOrUndefined} from "../../../../services/Validator";

function QuestionsDesktopLayout({children}) {
    // Used in QuestionsStepScreen to frame screen content in
    // desktop mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"hidden grow w-[960px] lg:flex lg:flex-col justify-start items-center space-y-24 pb-24"}>
            {children}
        </div>
    );
}

// EXPORT
export default QuestionsDesktopLayout