import {CircularProgress} from "@mui/material";

function SystemNeoScreenLoader() {
    // Used through the app to display one single screen centered
    // circular loader

    // BUILD
    return (
        <div className={"animate-appear w-full grow flex flex-col justify-center items-center space-y-6"}>
            <CircularProgress size={"56px"} style={{'color' : 'white'}} />
        </div>
    );
}

// EXPORT
export default SystemNeoScreenLoader