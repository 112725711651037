import {isNullOrUndefined} from "../../services/Validator";

function SystemScreenErrorTitleText({text}) {
    // Used through the app to display main title
    // text of screen error in both mobile and desktop modes

    // BUILD
    if(isNullOrUndefined(text))
        return null
    return (
        <div className={"w-full flex justify-center"}>
            <span className={"text-[18px] sm:text-[20px] text-center font-semibold text-white"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default SystemScreenErrorTitleText