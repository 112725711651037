import {isNullOrUndefined} from "../../../services/Validator";
import {useNavigate} from "react-router-dom";
import {kSystemIcons} from "../../../constants/icons";

function HomeNavigationItem({item, isSideNavigation}) {
    // Used in HomeTopBarNavigation to display
    // side navigation items

    // PROPERTIES
    let navigate = useNavigate()

    // BUILD
    if (isNullOrUndefined(item) || isNullOrUndefined(item.name) || isNullOrUndefined(item.route))
        return null
    return(
        <div className={`cursor-pointer w-full flex items-center ${isSideNavigation ? "font-medium text-greyLight7 hover:text-greyLight11" : "font-semibold text-greyLight9 hover:text-white"}`}
            onClick={() => navigate(item.route)}>
            <div className={"w-full flex items-start space-x-8"}>
                <i className={`material-icons ${isSideNavigation ? "text-[18px] mt-2" : "text-[24px]"}`}>
                    {item.icon}
                </i>
                <span className={`${isSideNavigation ? "text-[16px]" : "text-[18px]"} `}>
                    {item.name}
                </span>
            </div>
            <i className={`material-icons text-[24px]`}>
                {kSystemIcons.chevronRight}
            </i>
        </div>
    );
}

// EXPORT
export default HomeNavigationItem