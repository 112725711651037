import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {isNumber} from "chart.js/helpers";
import {isNullOrUndefined} from "../services/Validator";
import {get_single_question} from "../api/question";
import PreviewScreenError from "../components/errors/previewScreen/PreviewScreenError";
import SystemScreenLoader from "../components/loaders/SystemScreenLoader";
import PreviewMobileTopBar from "../components/bars/previewScreen/PreviewMobileTopBar";
import PreviewMobileLayout from "../components/layouts/previewScreen/PreviewMobileLayout";
import PreviewMobileQuestionsCard from "../components/cards/previewScreen/PreviewMobileQuestionsCard";
import {routes} from "../constants/routes";
import PreviewTabletTopBar from "../components/bars/previewScreen/PreviewTabletTopBar";
import PreviewContentLayout from "../components/layouts/previewScreen/PreviewContentLayout";
import PreviewDesktopTopBar from "../components/bars/previewScreen/PreviewDesktopTopBar";
import CorrectionContent from "../components/others/flashScreens/correctionStepScreen/CorrectionContent";
import CorrectionMobileContent from "../components/others/flashScreens/correctionStepScreen/CorrectionMobileContent";
import QuestionsCard from "../components/others/flashScreens/questionsStepScreen/QuestionsCard";
import PreviewLayout from "../components/layouts/previewScreen/PreviewLayout";

function PreviewScreen() {
    // Used in index.js to preview one specific Question in both Flash and Correction views

    // PROPERTIES
    let navigate = useNavigate();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [displayCorrection, setDisplayCorrection] = useState(false)
    const [question, setQuestion] = useState(null)

    // HOOKS
    // ---- Memorizes a function, preventing it from being redefined on every render, unless its dependencies change.
    const getSingleQuestion = useCallback(() => {
        if (isNullOrUndefined(id) || !isNumber(id) || id < 0)
            return
        // Set loader on and clear potential error
        setIsLoading(true)
        setHasError(false)
        // Perform
        get_single_question(id)
            .then((res) => {
                setQuestion(res)
                setHasError(false)
            }).catch((err) => {
            setHasError(true)
            console.error(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [id])
    // ---- Triggered at rendering : get single Question only in dev and staging env
    useEffect(() => {
        getSingleQuestion()
    }, [getSingleQuestion])

    // BUILD
    if (hasError)
        return <PreviewScreenError goBackToHome={() => {navigate(routes.home)}}
                                   retryFetching={getSingleQuestion} />
    if (isLoading)
        return <SystemScreenLoader />
    return (
        <div className={"animate-appearSlow overflow-auto w-full h-[100svh] flex flex-col justify-start items-center"}>
            {/** MOBILE */}
            <PreviewMobileTopBar goBackToHome={() => navigate(routes.home)}
                                 displayCorrection={displayCorrection}
                                 toggleView={toggleView} />
            <PreviewMobileLayout>
                {displayCorrection
                    ? <CorrectionMobileContent question={question}
                                               handleClose={null}/>
                    : <PreviewMobileQuestionsCard question={question}
                                                  optionSelected={null}
                                                  setOptionSelected={null}
                                                  displayPicture={false}
                                                  setDisplayPicture={null}
                                                  setOpenDontKnowDialog={null} />
                }
            </PreviewMobileLayout>
            {/** TABLET + DESKTOP */}
            {/** ---- TOP BARS */}
            <PreviewTabletTopBar goBackToHome={() => navigate(routes.home)}
                                 displayCorrection={displayCorrection}
                                 toggleView={toggleView} />
            <PreviewDesktopTopBar goBackToHome={() => navigate(routes.home)}
                                  displayCorrection={displayCorrection}
                                  toggleView={toggleView}/>
            {/** ---- CONTENT */}
            <PreviewLayout>
                <PreviewContentLayout extraClass={`${!displayCorrection ? "pb-24" : ""}`}>
                    {displayCorrection
                        ? <CorrectionContent question={question} />
                        : <QuestionsCard question={question}
                                         optionSelected={null}
                                         setOptionSelected={null}
                                         setOpenDontKnowDialog={null} />}
                </PreviewContentLayout>
            </PreviewLayout>
        </div>
    );

    // METHODS
    // ---- Toggle view to display
    function toggleView() {
        setDisplayCorrection(!displayCorrection)
    }
}

// EXPORT
export default PreviewScreen