import {CircularProgress} from "@mui/material";

function SystemQuestionPictureLoader({size}) {
    // Used through the app to display one single screen centered
    // circular loader

    // BUILD
    return (
        <div className={'animate-appear flex justify-center items-center mt-24 ' + size}>
            <CircularProgress size={"40px"}
                              style={{'color' : '#DBE2EC'}} />
        </div>
    );
}

// EXPORT
export default SystemQuestionPictureLoader