import {isArrayNullUndefinedOrEmpty} from "../../../services/Validator";
import ThemesItem from "../../items/themesScreen/ThemesItem";

function ThemesList({themes}) {
    // Used in ThemesScreen to display list of Theme items
    // made of A Theme name and short description

    // BUILD
    if (isArrayNullUndefinedOrEmpty(themes))
        return null
    return (
        <div className={"w-full flex flex-col space-y-24"}>
            {themes.map((theme, index) => (
                <ThemesItem key={index}
                    theme={theme} />
            ))}
        </div>
    );
}

// EXPORT
export default ThemesList