import SystemFullScreenCenteredLayout from "../../layouts/SystemFullScreenCenteredLayout";
import SystemScreenErrorLayout from "../../layouts/SystemScreenErrorLayout";
import SystemScreenErrorContent from "../../others/SystemScreenErrorContent";
import SystemScreenErrorActionButtons from "../../buttons/SystemScreenErrorActionButtons";
import {kErrorTexts} from "../../../constants/texts";

function PreviewScreenError({goBackToHome, retryFetching}) {
    // Used in GradeStepScreen to display error when
    // fetching flash levels from the api failed

    // BUILD
    return (
        <SystemFullScreenCenteredLayout extraClass={"bg-backgroundLight3"}>
            <SystemScreenErrorLayout>
                <SystemScreenErrorContent title={kErrorTexts.fetchQuestionTitle}
                                          subtitle={kErrorTexts.fetchQuestionSubtitle} />
                <SystemScreenErrorActionButtons onSecondaryPressed={goBackToHome}
                                                secondaryLabel={"Retour à l'accueil"}
                                                onPrimaryPressed={retryFetching}
                                                primaryLabel={"Réessayer"} />
            </SystemScreenErrorLayout>
        </SystemFullScreenCenteredLayout>
    );
}

// EXPORT
export default PreviewScreenError