import {renderFlashLogoTextSize} from "../../controllers/SystemFlashLogoTextController";
import {kFlashImages} from "../../constants/images";

function SystemFlashLogoImage({flashLogoSize}) {
    // Used through the app to display flash logo image in desktop
    // mode which is the text version of flash logo

    // BUILD
    return (
        <div className={`animate-appear ${renderFlashLogoTextSize(flashLogoSize)} mb-4`}>
            <img className={"object-contain h-full"}
                 src={kFlashImages.logoText}
                 alt="Flash logo" />
        </div>
    );
}

// EXPORT
export default SystemFlashLogoImage