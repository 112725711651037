
function ResultsQuestionItemChevronIcon({isHovered}) {
    // Used in QuizResultsQuestionItem to show right chevron icon

    // BUILD
    return (
        <div className={"w-[24px] min-w-[24px] h-[24px] min-h-[24px] leading-none flex items-center justify-end"}>
            <i className={`-mr-6 material-icons text-[24px] ${isHovered ? "text-white" : "text-greyLight7"}`}>chevron_right</i>
        </div>
    );
}

// EXPORT
export default ResultsQuestionItemChevronIcon