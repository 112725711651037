import HomeTitleText from "../../texts/homeScreen/HomeTitleText";
import HomeSubtitleText from "../../texts/homeScreen/HomeSubtitleText";
import {kHomeTexts} from "../../../constants/texts";
import {SystemPrimaryCustomButton} from "../../buttons/SystemCustomButton";
import {kButtonStyles} from "../../../constants/styles";
import {routes} from "../../../constants/routes";

function HomeShowCase() {
    // Used in LadingScreen to display master content
    // in desktop/tablet mode

    // BUILD
    return (
        <div className={"bg-backgroundLight1 w-full grow flex flex-col justify-center items-center"}>
            <div className={"w-[560px] md:w-[708px] grow flex flex-col space-y-48 md:space-y-56 justify-center pb-40"}>
                <div className={"w-full flex flex-col space-y-16 md:space-y-24"}>
                    <HomeTitleText />
                    <HomeSubtitleText />
                </div>
                <div className={"flex justify-center"}>
                    <SystemPrimaryCustomButton
                        label={kHomeTexts.callToActionLabel}
                        isDisabled={false}
                        isLoading={false}
                        isFullWidth={false}
                        sizeStyle={kButtonStyles.largeButtonStyle}
                        internalLink={routes.flash}
                        extraClass={"text-[18px]"}/>
                </div>
            </div>
        </div>
    );
}

// EXPORT
export default HomeShowCase