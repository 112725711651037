import SystemDraftQuestionOptionItem from "../items/SystemDraftQuestionOptionItem";
import {isArrayNullUndefinedOrEmpty} from "../../services/Validator";


function SystemDraftQuestionCardOptionsList({options, selectedOption, setSelectedOption}) {
    // Used in SystemDraftQuestionCard to display list
    // of question options in all mobile, tablet and desktop modes

    // BUILD
    if (isArrayNullUndefinedOrEmpty(options))
        return null
    return (
        <div className="w-full flex flex-col px-12 sm:px-16 lg:px-24 pb-12 sm:pb-16 lg:pb-24 pt-16 sm:pt-24 space-y-8 sm:space-y-12">
            {/** OPTIONS */}
            {options?.map((option, index) => (
                <SystemDraftQuestionOptionItem key={index}
                                               isLast={false}
                                               option={option}
                                               selectedOption={selectedOption}
                                               setSelectedOption={setSelectedOption} />
            ))}
        </div>
    );
}

// EXPORT
export default SystemDraftQuestionCardOptionsList