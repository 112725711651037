import {SystemPrimaryCustomButton} from "../components/buttons/SystemCustomButton";
import {kButtonStyles} from "../constants/styles";
import ErrorScreenImage from "../components/images/errorScreen/ErrorScreenImage";
import {routes} from "../constants/routes";

function ErrorScreen() {
    // Error Component used in index.js Browser Router, to manage routing not found error

    // BUILD
    return (
        <div className={"overflow-auto w-full h-screen flex flex-col justify-start items-center space-y-32 p-16 sm:p-32 lg:p-48"}>
            <ErrorScreenImage />
            <SystemPrimaryCustomButton
                label={"Revenir à l'accueil"}
                isDisabled={false}
                isLoading={false}
                isFullWidth={false}
                sizeStyle={kButtonStyles.largeButtonStyle}
                internalLink={routes.home}
                extraClass={kButtonStyles.flashRuleFixedWidth}/>
        </div>
    );
}

export default ErrorScreen