

// ---- Test if current option is selected option
export function isOptionSelected(option, optionSelected) {
    if (option !== null && optionSelected !== null) {
        return option.proposal === optionSelected.proposal
    } else {
        return false
    }
}

// ---- Render question option style
export function renderQuestionOptionStyle(option, optionSelected, isHovered) {
    if (isOptionSelected(option, optionSelected)) {
        return "border-primaryLight1 text-white"
    } else {
        return isHovered ? "border-greyLight7 text-white" : "border-greyLight5 text-greyLight11"
    }
}

// ---- Press dont know the answer help icon button
export function pressDontKnow(event, onDontKnowPress) {
    event.cancelBubble = true;
    if(event.stopPropagation) event.stopPropagation();
    onDontKnowPress()
}