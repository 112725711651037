import {isNullOrUndefined} from "../../../services/Validator";

function ThemesItemNameText({themeName}) {
    // Used in ThemesItem to display one single Theme
    // name text

    // BUILD
    if (isNullOrUndefined(themeName))
        return null
    return (
        <div>
            <span className={"text-[16px] lg:text-[18px] text-white font-semibold"}>
                {themeName}
            </span>
        </div>
    );
}

// EXPORT
export default ThemesItemNameText