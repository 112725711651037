import SystemUmmiaLogoTextImage from "../../images/SystemUmmiaLogoTextImage";
import {LogoTextSize} from "../../../controllers/SystemUmmiaLogoTextController";
import SystemTabletTopBarLayout from "../../layouts/SystemTabletTopBarLayout";
import HomeDrawerIcon from "../../icons/homeScreen/HomeDrawerIcon";

function HomeTabletTopBar ({openDrawer}) {
    // Used in HomeScreen to display screen top bar

    // BUILD
    return (
        <SystemTabletTopBarLayout>
            <SystemUmmiaLogoTextImage logoTextSize={LogoTextSize.SMALL} />
            <HomeDrawerIcon onPressed={openDrawer} />
        </SystemTabletTopBarLayout>
    );
}

// EXPORT
export default HomeTabletTopBar