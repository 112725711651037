import {kFlashImages, kSystemImages} from "../../../constants/images";

// METHODS
// ---- Render Flash score bear emoji regarding success ratio

export function renderScoreEmoji(successRatio) {
    if (successRatio === 100) {
        return kFlashImages.resultsEmojiBearSuccess
    } else if (successRatio >= 80) {
        return kFlashImages.resultsEmojiBearAlmost
    } else if (successRatio >= 60) {
        return kFlashImages.resultsEmojiBearAverage
    } else if (successRatio >= 40) {
        return kFlashImages.resultsEmojiBearSceptic
    } else if (successRatio >= 20) {
        return kFlashImages.resultsEmojiBearConfused
    } else {
        return kFlashImages.resultsEmojiBearConfused
    }
}

// ---- Render Flash score bear ice emoji regarding success ratio
export function renderScoreIceEmoji(successRatio) {
    if (successRatio === 100) {
        return kSystemImages.iceStar
    } else if (successRatio >= 80) {
        return kSystemImages.iceBlink
    } else if (successRatio >= 60) {
        return kSystemImages.iceNormal
    } else if (successRatio >= 40) {
        return kSystemImages.iceSkeptic
    } else if (successRatio >= 20) {
        return kSystemImages.iceConfused
    } else {
        return kSystemImages.iceConfused
    }
}