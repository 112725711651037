import {isNullOrUndefined} from "../../../../services/Validator";
import {MathJax, MathJaxContext} from "better-react-mathjax";

function ResultsQuestionStatementText({statement, isHovered}) {
    // Used in ResultsQuestionItem to display question statement

    // BUILD
    if (isNullOrUndefined(statement))
        return null;
    return (
        <div className={`flex items-start justify-start ${isHovered ? "text-white" : "text-greyLight11"} w-full text-[16px] font-semibold overflow-hidden`}>
            <MathJaxContext>
                <MathJax inline dynamic>
                    <span className={"truncate"}>
                        {statement}
                    </span>
                </MathJax>
            </MathJaxContext>
        </div>
    );
}

// EXPORT
export default ResultsQuestionStatementText