

// PROPERTIES
export const ClosingIconSize = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large"
}

// METHODS
// ---- Render closing icon position
export function renderClosingIconPosition(closingIconSize) {
    switch(closingIconSize) {
        case ClosingIconSize.SMALL:
            return "top-[8px] right-[8px]"
        case ClosingIconSize.MEDIUM:
            return "top-[12px] right-[12px]"
        case ClosingIconSize.LARGE:
            return "top-[24px] right-[24px]"
        default:
            return "top-[16px] right-[16px]"
    }
}

// ---- Render closing icon size
export function renderClosingIconSize(closingIconSize) {
    switch(closingIconSize) {
        case ClosingIconSize.SMALL:
            return "text-[24px]"
        case ClosingIconSize.MEDIUM:
            return "text-[32px]"
        case ClosingIconSize.LARGE:
            return "text-[40px]"
        default:
            return "text-[32px]"
    }
}