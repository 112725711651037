
function ResultsCounterBadge({color, score}) {
    // Used in ResultsDetailedScoreItem to show answers type label

    // BUILD
    return (
        <div className={(color ?? "bg-greyLight7") + " w-[32px] lg:w-[40px] min-w-[32px] lg:min-w-[40px] h-[32px] lg:h-[40px] min-h-[32px] lg:min-h-[40px] rounded-[8px] leading-none flex items-center justify-center"}>
            <span className="font-medium text-[18px] sm:text-[20px] lg:text-[22px] text-white">
                {score ?? "?"}
            </span>
        </div>
    );
}

// EXPORT
export default ResultsCounterBadge