import {isNullOrUndefined} from "../../../services/Validator";
import ThemesItemNameText from "../../texts/themesScreen/ThemesItemNameText";
import ThemesItemDescriptionText from "../../texts/themesScreen/ThemesItemDescriptionText";

function ThemesItem({theme}) {
    // Used in ThemesList to display one single Theme made of
    // Theme name and short description

    // BUILD
    if (isNullOrUndefined(theme?.name) || isNullOrUndefined(theme?.shortDescription))
        return null
    return (
        <div className={"w-full flex flex-col space-y-6 lg:space-y-8 justify-start items-start"}>
            <ThemesItemNameText themeName={theme.name} />
            <ThemesItemDescriptionText themeDescription={theme.shortDescription} />
        </div>
    );
}

// EXPORT
export default ThemesItem