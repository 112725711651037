import {api} from "./routes";
import {generatePath} from "react-router";
import {makeRequest, Methods} from "./request";

// List of methods triggering PageViewMetric related api calls

// METHODS
// ---- Record page view metric based on eventName
export function record_page_view_metric(data) {
    const url = api.domain + generatePath(api.routes.recordPageViewMetric)
    return makeRequest(url, Methods.POST, data)
}