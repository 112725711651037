import {isNullOrUndefined} from "../../../../services/Validator";

function ResultsScorePercentageText({correctAnswers, total, toggleShowPercentage}) {
    // Used in ResultsTotalScore to display flash
    // score in percentages

    // BUILD
    if (isNullOrUndefined(correctAnswers) || isNullOrUndefined(total))
        return null;
    return (
        <div>
            <span className="text-white cursor-pointer text-[40px] font-medium whitespace-nowrap"
                  onClick={() => toggleShowPercentage()} >
                {Math.round(100*correctAnswers/total) + " %"}
            </span>
        </div>
    );
}

// EXPORT
export default ResultsScorePercentageText