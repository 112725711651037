import {useState} from "react";
import {kMethodQuestion} from "../../../constants/objects";
import AboutDesktopTabletSectionLayout from "../../layouts/aboutScreen/AboutDesktopTabletSectionLayout";
import AboutDesktopTabletTopTitleLayout from "../../layouts/aboutScreen/AboutDesktopTabletTopTitleLayout";
import AboutDesktopTabletSectionTopTitleText from "../../texts/aboutScreen/AboutDesktopTabletSectionTopTitleText";
import AboutDesktopTabletSectionCoreText from "../../texts/aboutScreen/AboutDesktopTabletSectionCoreText";
import AboutDesktopTabletItemLayout from "../../layouts/aboutScreen/AboutDesktopTabletItemLayout";
import AboutDesktopTabletItemTitleText from "../../texts/aboutScreen/AboutDesktopTabletItemTitleText";
import AboutDesktopTabletMethodSolutionIntroText
    from "../../texts/aboutScreen/AboutDesktopTabletMethodSolutionIntroText";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";
import AboutImage from "../../images/aboutScreen/AboutImage";
import SystemQuestionKnowledge from "../SystemQuestionKnowledge";
import SystemOptionsCard from "../../cards/SystemOptionsCard";
import {kAboutMethodTexts} from "../../../constants/texts";
import SystemDraftQuestionCard from "../../cards/SystemDraftQuestionCard";
import {kSystemImages} from "../../../constants/images";

function AboutDesktopTabletMethodSection() {
    // Used in AboutDesktopTabletContent to display content
    // of method section in desktop / tablet modes

    // PROPERTIES
    const [selectedOption, setSelectedOption] = useState(null)

    // BUILD
    return(
        <AboutDesktopTabletSectionLayout>
            {/** STATEMENT */}
            <AboutDesktopTabletTopTitleLayout>
                <AboutImage imageUrl={kSystemImages.flashLogo} />
                <AboutDesktopTabletSectionTopTitleText title={kAboutMethodTexts.title} />
            </AboutDesktopTabletTopTitleLayout>
            <SystemCustomDivider color={'bg-greyLight5'} />
            {/** PROBLEM */}
            <AboutDesktopTabletSectionCoreText text={kAboutMethodTexts.intro} />
            <AboutDesktopTabletItemLayout>
                <AboutDesktopTabletItemTitleText title={kAboutMethodTexts.problemOneTitle} />
                <AboutDesktopTabletSectionCoreText text={kAboutMethodTexts.problemOne} />
            </AboutDesktopTabletItemLayout>
            <AboutDesktopTabletItemLayout>
                <AboutDesktopTabletItemTitleText title={kAboutMethodTexts.problemTwoTitle} />
                <AboutDesktopTabletSectionCoreText text={kAboutMethodTexts.problemTwo} />
            </AboutDesktopTabletItemLayout>
            <SystemCustomDivider color={'bg-greyLight5'} />
            {/** SOLUTION */}
            <AboutDesktopTabletMethodSolutionIntroText />
            <SystemDraftQuestionCard question={kMethodQuestion}
                                     selectedOption={selectedOption}
                                     setSelectedOption={setSelectedOption} />
            <AboutDesktopTabletItemLayout>
                <AboutImage imageUrl={'/images/aboutScreen/atom.png'} />
                <AboutDesktopTabletItemTitleText title={kAboutMethodTexts.solutionOneTitle} />
                <AboutDesktopTabletSectionCoreText text={kAboutMethodTexts.solutionOne} />
            </AboutDesktopTabletItemLayout>
            <AboutDesktopTabletItemLayout>
                <AboutImage imageUrl={'/images/aboutScreen/pastFuture.png'} />
                <AboutDesktopTabletItemTitleText title={kAboutMethodTexts.solutionTwoTitle} />
                <AboutDesktopTabletSectionCoreText text={kAboutMethodTexts.solutionTwo} />
            </AboutDesktopTabletItemLayout>
            <AboutDesktopTabletItemLayout>
                <AboutImage imageUrl={'/images/aboutScreen/repeat.png'} />
                <AboutDesktopTabletItemTitleText title={kAboutMethodTexts.solutionThreeTitle} />
                <AboutDesktopTabletSectionCoreText text={kAboutMethodTexts.solutionThree} />
            </AboutDesktopTabletItemLayout>
            <AboutDesktopTabletItemLayout>
                <AboutImage imageUrl={'/images/aboutScreen/standUp.png'} />
                <AboutDesktopTabletItemTitleText title={kAboutMethodTexts.solutionFourTitle} />
                <AboutDesktopTabletSectionCoreText text={kAboutMethodTexts.solutionFour} />
            </AboutDesktopTabletItemLayout>
            <SystemQuestionKnowledge knowledge={"Knowledge"} />
            <SystemOptionsCard question={kMethodQuestion} />
        </AboutDesktopTabletSectionLayout>
    );
}

// EXPORT
export default AboutDesktopTabletMethodSection