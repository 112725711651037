import {isNullOrUndefined} from "../../services/Validator";
import {kSystemIcons} from "../../constants/icons";

function SystemQuestionCardHeaderMainText({subtheme}) {
    // Used in SystemQuestionCardHeader to display
    // subtheme text in all mobile, tablet and desktop modes

    // BUILD
    if (isNullOrUndefined(subtheme))
        return null
    return (
        <div className={"flex justify-start items-start space-x-4 text-greyLight5"}>
            <i className="material-icons text-[16px]">{kSystemIcons.theme}</i>
            <span className={`font-medium text-[14px] sm:text-[16px] leading-none`}>
                {subtheme}
            </span>
        </div>
    );
}

// EXPORT
export default SystemQuestionCardHeaderMainText