import {isNullOrUndefined} from "../../services/Validator";

function SystemOptionTopContentLayout({children}) {
    // Used through the app to frame option top content in all
    // mobile, tablet, and desktop modes

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"w-full flex flex-col space-y-2 sm:space-y-4 lg:space-y-6"}>
            {children}
        </div>
    );
}

// EXPORT
export default SystemOptionTopContentLayout