import {isArrayNullUndefinedOrEmpty} from "../../../../services/Validator";
import ResultsQuestionItem from "../../../items/flashScreens/resultsStepScreen/ResultsQuestionItem";

function ResultsQuestionsList({questions}) {
    // Used in ResultsStepScreen to display list of current
    // flash questions

    // BUILD
    if (isArrayNullUndefinedOrEmpty(questions))
        return null;
    return (
        <div className={"w-full grow flex flex-col justify-start py-10 space-y-6"}>
            {questions?.map((question, index) => (
                <ResultsQuestionItem key={index}
                                         index={index}
                                         total={questions.length}
                                         question={question} />
            ))}
        </div>
    );
}

// EXPORT
export default ResultsQuestionsList