import {isNullOrUndefined} from "../../services/Validator";

function SystemOptionContentLayout({children}) {
    // Used through the app to frame option content
    // in all mobile, tablet and desktop modes

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-auto bg-backgroundLight5 w-full flex flex-col py-16 lg:py-24 px-16 lg:px-24 space-y-6 sm:space-y-12 lg:space-y-16"}>
            {children}
        </div>
    );
}

// EXPORT
export default SystemOptionContentLayout