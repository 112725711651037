import SystemDesktopFlashToHomeButton from "../../buttons/SystemDesktopFlashToHomeButton";
import SystemDesktopTopBarLayout from "../../layouts/SystemDesktopTopBarLayout";
import {Switch} from "@mui/material";
import PreviewLogoTextImage from "../../images/previewScreen/PreviewLogoTextImage";
import {kPreviewSwitchStyle} from "../../../constants/styles";
import {PreviewLogoTextSize} from "../../../controllers/previewScreen/PreviewLogoTextController";

function PreviewDesktopTopBar({goBackToHome, displayCorrection, toggleView}) {
    // Used in GradeStepScreen to display top bar in desktop or tablet
    // mode made of logo and back to home button

    // BUILD
    return (
        <SystemDesktopTopBarLayout>
            <PreviewLogoTextImage logoSize={PreviewLogoTextSize.SMALL} />
            <div className={"flex justify-end items-center space-x-24"}>
                <div className={"flex justify-end items-center space-x-6 font-medium"}>
                    <span className={`${displayCorrection ? "text-greyLight7" : "text-greyLight11 font-semibold"}`}>
                        Flash
                    </span>
                    <Switch value={displayCorrection}
                            onChange={() => toggleView()}
                            sx={kPreviewSwitchStyle} />
                    <span className={`${displayCorrection ? "text-greyLight11 font-semibold" : "text-greyLight7"}`}>
                        Correction
                    </span>
                </div>
                <span className={"mb-4 text-greyLight9"}>
                    |
                </span>
                <SystemDesktopFlashToHomeButton goBackToHome={goBackToHome} />
            </div>
        </SystemDesktopTopBarLayout>
    );
}

// EXPORT
export default PreviewDesktopTopBar