import {isArrayNullUndefinedOrEmpty, isNullOrUndefined} from "../../../../services/Validator";
import ParameterSubthemesContentHeader from "./ParameterSubthemesContentHeader";
import ParameterSubthemesList from "../../../lists/flashScreens/parameterStepScreen/ParameterSubthemesList";
import ParameterLevelAsList from "../../../lists/flashScreens/parameterStepScreen/ParameterLevelAsList";
import SystemScreenError from "../../../errors/SystemScreenError";
import ParameterNoGradeSelected from "../../flashScreens/parameterStepScreen/ParameterNoGradeSelected";
import {kFlashSubthemesError} from "../../../../constants/errors";
import ParameterSubthemesLoader from "../../../loaders/flashScreens/parameterStepScreen/ParameterSubthemesLoader";

function ParameterSubthemesContent({selectedSubtheme,
                                           selectedGrade,
                                           isSubthemesLoading,
                                           subthemesError,
                                           subthemes,
                                           levelAs,
                                           toggleLevelA,
                                           selectSubtheme,
                                           selectedLevelAs}) {
    // Used in ParameterCoreContent to display Subthemes and LevelAs
    // related components in desktop and tablet modes

    // PROPERTIES
    const subthemesCount = subthemes?.length
    const levelAsCount = levelAs?.length
    const selectedLevelAsCount = selectedLevelAs?.length

    // BUILD
    if (!isNullOrUndefined(subthemesError))
        return <SystemScreenError error={subthemesError} />
    if (isSubthemesLoading)
        return <ParameterSubthemesLoader />
    if (isNullOrUndefined(selectedGrade))
        return <ParameterNoGradeSelected />
    if (isArrayNullUndefinedOrEmpty(subthemes))
        return <SystemScreenError error={kFlashSubthemesError} />
    return (
        <div className={"overflow-auto animate-appearSlow w-full grow flex flex-col justify-start shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px] mt-24"}>
            <ParameterSubthemesContentHeader subthemesCount={subthemesCount}
                                             levelAsCount={levelAsCount}
                                             selectedLevelAsCount={selectedLevelAsCount} />
            <div className={"overflow-auto w-full grow flex bg-backgroundLight3 border-1 border-backgroundLight5 rounded-b-[16px]"}>
                <ParameterSubthemesList subthemes={subthemes}
                                        selectSubtheme={selectSubtheme}
                                        selectedSubtheme={selectedSubtheme} />
                <ParameterLevelAsList levelAs={levelAs}
                                      selectedLevelAs={selectedLevelAs}
                                      toggleLevelA={toggleLevelA} />
            </div>
        </div>
    );
}

// EXPORT
export default ParameterSubthemesContent