import {kSystemIcons} from "../../constants/icons";

function SystemZoomedPictureError() {
    // Used through the app to display error when
    // zooming on Question Picture is failing

    // BUILD
    return (
        <div className={"flex flex-col justify-start items-center space-y-6 w-[280px] sm:w-[400px]"}>
            <i className={`material-icons text-[24px] lg:text-[32px] text-greyLight7`}>
                {kSystemIcons.error}
            </i>
            <span className={"text-[16px] lg:text-[18px] font-medium text-greyLight7 text-center"}>
                {"L'agrandissement de l'image n'est pas disponible, veuillez réessayer plus tard"}
            </span>
        </div>
    );
}

// EXPORT
export default SystemZoomedPictureError