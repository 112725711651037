import {isNullOrUndefined} from "../../../services/Validator";

function ThemesItemDescriptionText({themeDescription}) {
    // Used in ThemesItem to display Theme short description
    // text

    // BUILD
    if (isNullOrUndefined(themeDescription))
        return null
    return (
        <div>
            <span className={"text-[16px] lg:text-[18px] text-greyLight11 font-medium"}>
                {themeDescription}
            </span>
        </div>
    );
}

// EXPORT
export default ThemesItemDescriptionText