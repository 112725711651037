
// Tailwind styles used through the app
// ---- Button styles
export const kButtonStyles = {
    // ---- Primary button
    primaryButtonStyle: "w-full bg-primary text-[16px] text-white font-medium leading-none shadow-[inset_0px_1px_0px_rgba(255,255,255,0.5)]",
    primaryHoveredButtonStyle: "w-full bg-primaryLight1 text-[16px] text-white font-medium leading-none shadow-[inset_0px_1px_0px_rgba(255,255,255,0.5)]",
    primaryDisabledButtonStyle: "w-full bg-greyLight1 text-[16px] text-greyLight7 font-medium leading-none shadow-[inset_0px_1px_0px_rgba(82,96,120,1)]",
    // ---- Secondary button
    secondaryButtonStyle: "w-full bg-primaryDark7 shadow-[inset_0px_0px_0px_1px_rgba(53,168,232,1)] rounded-[12px] text-[16px] text-primaryDark1 font-medium leading-none",
    secondaryHoveredButtonStyle: "w-full bg-primaryDark6 shadow-[inset_0px_0px_0px_1px_rgba(108,201,251,1)] rounded-[12px] text-[16px] text-primaryLight1 font-medium leading-none",
    // ---- Error button
    errorButtonStyle: "w-full border-red border-2 rounded-[16px]",
    errorHoveredButtonStyle: "w-full border-red border-2 rounded-[8px]",
    // ---- Button size
    smallButtonStyle: "py-8 px-16 rounded-[8px]",
    mediumButtonStyle: "py-12 px-20 rounded-[12px]",
    largeButtonStyle: "py-16 px-32 rounded-[12px]",
    // ---- Button extra style
    flashRuleFixedWidth: "w-[304px]"
}

// ---- Dialog styles
export const kModalStyles = {
    dialogStyle: "w-[280px] sm:w-[400px] flex flex-col items-center space-y-16 bg-backgroundLight3 p-16 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] rounded-[16px] outline-none",
    dialogZoomedPictureStyle: "flex justify-center items-center bg-backgroundLight3 p-12 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] rounded-[16px] outline-none",
}

// ---- Preview Switch style
export const kPreviewSwitchStyle = {
    "&.MuiSwitch-root .MuiSwitch-switchBase": {
        color: "#98D7FB"
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: "#18577C",
    },
    "&.MuiSwitch-root .Mui-checked": {
        color: "#98D7FB"
    }
}

export const checkboxStyle = {
    color: "#C0CBDA",
    '&.Mui-checked': {
        color: "#B1E1FC",
    },
}