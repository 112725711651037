import KnowledgesItem from "../../items/knowledgesScreen/KnowledgesItem";
import KnowledgesTypeText from "../../texts/knowledgesScreen/KnowledgesTypeText";
import {isArrayNullUndefinedOrEmpty} from "../../../services/Validator";

function KnowledgesTypedList({type, amount, typedList}) {
    // Used in KnowledgesCoreContent to display one single typed
    // list of Knowledges

    // BUILD
    if (isArrayNullUndefinedOrEmpty(typedList))
        return null
    return (
        <div className={"w-full flex flex-col space-y-12"} >
            <KnowledgesTypeText type={type} amount={amount} />
            <div className={"flex flex-col space-y-12"}>
                {typedList.map((item, index) => (
                    <KnowledgesItem key={index}
                                    item={item} />
                ))}
            </div>
        </div>
    );
}

// EXPORT
export default KnowledgesTypedList