import SystemCustomDivider from "../../dividers/SystemCustomDivider";
import SystemQuestionCardQueryText from "../../texts/SystemQuestionCardQueryText";
import {get_nested} from "../../../services/JsonHandler";
import {isNullOrUndefined} from "../../../services/Validator";
import SystemQuestionPicture from "../../images/SystemQuestionPicture";
import QuestionsMobileQuestionImageLayout
    from "../../layouts/flashScreens/questionsStepScreen/QuestionsMobileQuestionImageLayout";
import QuestionsMobileCardOptionsList
    from "../../lists/flashScreens/questionsStepScreen/QuestionsMobileCardOptionsList";
import QuestionsMobileCardHeader from "../../others/flashScreens/questionsStepScreen/QuestionsMobileCardHeader";

function PreviewMobileQuestionsCard({question,
                             optionSelected,
                             setOptionSelected,
                             setOpenDontKnowDialog}) {
    // Used in QuestionsStepScreen to display question content in a card
    // in mobile format

    // PROPERTIES
    // ---- Question
    const questionOptions = get_nested(['options'], question, null)
    const questionQuery = get_nested(['query'], question, null)
    const questionPictureUrl = get_nested(['questionPicture', 'filePath'], question, null)
    const questionPictureRatio = get_nested(['questionPicture', 'ratio'], question, null)

    // BUILD
    return (
        <div className={"overflow-auto bg-backgroundLight3 w-full grow min-h-[320px] flex flex-col sm:hidden justify-start pb-16 rounded-t-[8px]"}>
            {/** HEADER */}
            <QuestionsMobileCardHeader question={question} />
            <SystemCustomDivider color={'bg-greyLight5'} extraClass={"min-h-[1px]"} />
            {/** CORE */}
            <SystemQuestionCardQueryText query={questionQuery} />
            {/*<QuestionsMobileQuestionImageLayout>*/}
            {/*    <SystemQuestionPicturePlaceholder pictureRatio={PictureRatio.PORTRAIT}*/}
            {/*                                      isForMobile={true} />*/}
            {/*</QuestionsMobileQuestionImageLayout>*/}
            {!isNullOrUndefined(questionPictureUrl)
                ? <QuestionsMobileQuestionImageLayout>
                    <SystemQuestionPicture pictureUrl={questionPictureUrl}
                                           pictureRatio={questionPictureRatio}
                                           isForMobile={true}
                                           openZoom={null} />
                  </QuestionsMobileQuestionImageLayout>
                : null}
            <QuestionsMobileCardOptionsList options={questionOptions}
                                            optionSelected={optionSelected}
                                            setOptionSelected={setOptionSelected}
                                            onDontKnowPress={() => setOpenDontKnowDialog(true)} />
        </div>
    );
}

// EXPORT
export default PreviewMobileQuestionsCard