import {isNullOrUndefined} from "../../services/Validator";

function SystemOptionBottomContentLayout({children}) {
    // Used through the app to frame option bottom content
    // in all mobile, tablet and desktop modes

    // BUILD
    if(isNullOrUndefined(children))
        return null
    return (
        <div className={"w-full flex flex-col items-center justify-center space-y-12"}>
            {children}
        </div>
    );
}

// EXPORT
export default SystemOptionBottomContentLayout