import {isNullOrUndefined} from "../../../../services/Validator";
import {kSystemIcons} from "../../../../constants/icons";

function QuestionsMobileCardHeaderSubText({task}) {
    // Used in QuestionsMobileCardHeader to display question
    // level in mobile mode

    // BUILD
    if (isNullOrUndefined(task) || isNullOrUndefined(task))
        return null
    return (
        <div className={`flex space-x-4 justify-start items-start leading-tight text-greyLight5`}>
            <i className="material-icons text-[14px] mt-2">
                {kSystemIcons.task}
            </i>
            <span className={`text-[14px] font-medium`}>
                {task}
            </span>
        </div>
    );
}

// EXPORT
export default QuestionsMobileCardHeaderSubText