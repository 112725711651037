import {isNullOrUndefined} from "../../services/Validator";
import SystemCorrectOptionIcon from "../icons/SystemCorrectOptionIcon";

function SystemOptionsCardHeaderItem({index, correctOptionIndex, isSelected, pressOption}) {
    // Used through the app to display one single item in
    // correction card header for all mobile, tablet, desktop
    // modes

    // PROPERTIES
    const isCorrectOption = !isNullOrUndefined(correctOptionIndex) ? (index === correctOptionIndex) : false

    // BUILD
    if (isNullOrUndefined(index))
        return null
    return (
        <div className={`cursor-pointer w-full flex justify-center items-center ${isCorrectOption ? "space-x-4" : "space-x-0"} py-8 sm:py-10 lg:py-12 border-b-greyLight5 border-l-greyLight5 border-r-greyLight5 border-b-[1px] ${index === 1 ? "border-l-1 border-r-1" : ""} ${isSelected ? `font-medium text-white border-b-white border-b-[1px]` : `font-normal text-greyLight7 border-b-greyLight5`}`}
             onClick={pressOption}>
            <span className={`hidden sm:block text-[14px] md:text-[16px] ${isSelected ? "text-greyLight11" : "text-greyLight7"}`}>
                {"Proposition " + (index + 1)}
            </span>
            <span className={`block sm:hidden text-[14px] md:text-[16px] ${isSelected ? "text-greyLight11" : "text-greyLight7"}`}>
                {"Réponse " + (index + 1)}
            </span>
            {isCorrectOption
                ? <SystemCorrectOptionIcon isSelected={isSelected} />
                : null}
        </div>
    );
}

// EXPORT
export default SystemOptionsCardHeaderItem