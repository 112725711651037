import Modal from "@mui/material/Modal";
import {Slide} from "@mui/material";
import HomeDrawerUserSection from "./HomeDrawerUserSection";
import HomeDrawerMainNavigation from "./HomeDrawerMainNavigation";
import HomeDrawerSideNavigation from "./HomeDrawerSideNavigation";
import HomeAppVersionNumber from "./HomeAppVersionNumber";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";

function HomeDrawer({open, handleClose}) {
    // Used in HomeScreen to display Drawer as a side modal
    // sliding from the left to the right

    // BUILD
    return(
        <Modal open={open}
               onClose={handleClose}>
            <Slide direction="right" in={open} mountOnEnter unmountOnExit>
                <div className="overflow-auto bg-backgroundLight3 w-[280px] h-[100svh] flex flex-col justify-start items-center outline-none py-24">
                    <div className={"w-full grow flex flex-col"}>
                        <HomeDrawerUserSection />
                        <HomeDrawerMainNavigation />
                    </div>
                    <div className={"w-full flex flex-col space-y-16"}>
                        <SystemCustomDivider color={"bg-greyLight5"} />
                        <HomeDrawerSideNavigation />
                        <HomeAppVersionNumber />
                    </div>
                </div>
            </Slide>
        </Modal>
    );
}

// EXPORT
export default HomeDrawer