import LegalsTabletTopBar from "../components/bars/legalsScreen/LegalsTabletTopBar";
import LegalsDesktopTopBar from "../components/bars/legalsScreen/LegalsDesktopTopBar";
import LegalsContentLayout from "../components/layouts/legalsScreen/LegalsContentLayout";
import LegalsContent from "../components/others/legalsScreen/LegalsContent";
import LegalsLayout from "../components/layouts/legalsScreen/LegalsLayout";

function LegalsScreen({legalContentKey}) {
    // Used in index.js by the router as an entry point
    // for compulsory legal texts, in tablet and desktop modes

    // BUILD
    return (
        <div className={"animate-appearSlow overflow-auto w-full h-[100svh] flex flex-col justify-start items-center"}>
            <LegalsTabletTopBar />
            <LegalsDesktopTopBar />
            <LegalsLayout>
                <LegalsContentLayout>
                    <LegalsContent legalContentKey={legalContentKey} />
                </LegalsContentLayout>
            </LegalsLayout>
        </div>
    );

}

// EXPORT
export default LegalsScreen