
// Used through the app to provide constant texts across components

// PROPERTIES
// ---- Errors
export const kErrorTexts = {
    // PreviewQuestion screen
    fetchQuestionTitle: "La question demandée n'est pas trouvable",
    fetchQuestionSubtitle: "Veuillez contacter un administrateur",
    // Neutral
    neutralErrorStatementText: "Une erreur serveur est survenue",
    neutralErrorReassuranceText: "Nous faisons notre possible pour résoudre le problème au plus vite"
}

// ---- Voids
export const kVoidTexts = {
    levelAsList: "Sélectionne un thème pour afficher les savoir-faire associés"
}

// ---- mobileRedirectScreen
export const kMobileRedirectTexts = {
    infoTitle: "Notre application est disponible en version mobile pour Android et IOs",
    infoSubtitle: "Clique sur le lien correspondant pour accéder au téléchargement"
}

// ---- flashScreens
export const kFlashTexts = {
    back: "Retour",
    backToHome: "Retour à l'accueil",
    dontKnowTitle: "Tu ne connais pas la réponse ?",
    dontKnowSubtitle: "Il est possible que tu n'ais pas vu la notion en classe, cela nous aidera à mieux répertorier les questions",
    perfectScore: "Félicitations pour ce score parfait. Tu sembles prêt-e pour l'évaluation",
    veryGoodScore: "C'est très bien, essaye de recommencer pour viser le score parfait",
    goodScore: "C'est bien, continue comme ça. Essaye de relancer un flash pour t'assurer que tu maitrises ce savoir-faire",
    averageScore: "Tu as obtenu la moyenne. Essaye de comprendre tes erreurs et la connaissance associée pour vraiment maitriser ce savoir-faire",
    underScore: "C'est en dessous de la moyenne. En allant dans la correction, tu as toutes les armes pour comprendre tes erreurs et progresser. Courage",
    nullScore: "Garde confiance en toi. En allant dans la correction, tu as toutes les armes pour comprendre la connaissance et comment l'appliquer",
    appreciationError: "Nous avons rencontré une erreur avec ton score. Continue à pratiquer pour progresser",
    leaveCurrentFlashTitle: "Es-tu certain.e de vouloir quitter la série en cours ?",
    leaveCurrentFlashSubtitle: "Tu perdras toute ta progression et tu ne pourras pas consulter la correction des questions auxquelles tu as déjà répondues",
    leaveFlash: "Quitter le flash",
    noGradeSelected: "Ça va bientôt commencer !\nJe te conseille de préparer un crayon et un brouillon. Tu peux ensuite sélectionner un niveau puis des savoir-faire",
    // ---- ----  parameterStepScreen
    parameterScreenTitle: "Ça va bientôt commencer !",
    parameterScreenSubtitle: "Nous te conseillons de préparer un crayon et un brouillon",
    // ---- ---- resultsStepScreen
    resultsDesktopTopTitle: "Félicitations, tu as fini la série !",
    resultsDesktopTopSubtitle: "Tu peux maintenant consulter la correction par question",
    resultsTabletTopTitle: "Tu as fini la série !",
    resultsTabletTopSubtitle: "Tu peux maintenant consulter la correction",
}

// ---- homeScreen
export const kHomeTexts = {
    callToActionLabel: "C'est parti !",
    punchline: "Progresse en mathématiques en maitrisant les fondamentaux\ndu CM1 à la 3<sup>ème</sup>",
    subPunchline: "Réponds à des séries de questions flash, adaptées à ton niveau scolaire et abordant tous les thèmes du programme de l’Éducation Nationale",
    navAbout: "À propos",
    navKnowledges: "Les connaissances",
    navLegals: "Mentions légales",
    navPrivacy: "Confidentialité",
    navThemes: "Le programme",
}

// ---- knowledgesScreen
export const kKnowledgesTexts = {
    noGradeSelected: 'Commence par sélectionner le niveau dont tu veux afficher les connaissances',
    definitionType: "Définition",
    conventionType: "Convention",
    formulaType: "Formule",
    methodType: "Méthode",
    theoremType: "Théorème",
    propertyType: "Propriété",
    unknownType: "Type inconnu"
}

// ---- themesScreen
export const kThemesTexts = {
    introText: "Le programme scolaire officiel de mathématiques est le fondement du contenu de l'application. Il est actuellement découpé en 5 grands thèmes qui sont abordés à différents moments de ta scolarité. Ci-dessous, tu trouveras le détail de chaque grand thème."
}

// ---- aboutScreen
// ---- ----- Mission sub-screen
export const kAboutMissionTexts = {
    intro: "De nombreuses études françaises et internationales montrent que le niveau baisse en mathématiques. Il semblerait que les élèves soient lents en calcul, manquent d’intuition et de réflexes. Pourtant, cette discipline n’a jamais été autant présente et indispensable dans notre société (intelligence artificielle, big data, télécommunications).",
    valuesOne: "C’est avec la conviction que ",
    valuesTwo: "chaque enfant peut et doit réussir en mathématiques ",
    valuesThree: "que nous avons développé Ummia. À terme, nous souhaitons devenir le compagnon indispensable d’un élève dans son apprentissage des mathématiques au collège.",
    mission: "À travers le contenu proposé dans l’application, nous souhaitons que chaque élève :",
    missionOne: "Comprenne les liens entre les notions du programme.",
    missionTwo: "Perçoive l’erreur comme une occasion d’apprendre.",
    missionThree: "Devienne autonome et performant dans son travail personnel.",
    outroOne: "Le premier outil que nous avons conçu à ce jour, ",
    outroTwo: "les questions flash",
    outroThree: ", doit permettre aux élèves de renforcer et de consolider leurs fondamentaux.",
}

// ---- ---- Method sub-screen
export const kAboutMethodTexts = {
    title: "Les questions flash, la clé pour consolider ses bases en mathématiques",
    intro: "En tant qu’enseignant, nous avons remarqué que de nombreux élèves en difficulté, malgré leurs efforts, ne parviennent pas à améliorer significativement leurs résultats. Cela peut entraîner un sentiment de découragement puis d’abandon vis-à-vis de la matière. Notre équipe a identifié deux causes majeures pouvant expliquer ce phénomène :",
    problemOneTitle: "Les savoirs fondamentaux ne sont pas maitrisés",
    problemOne: "Il y a la croyance que connaitre sa leçon suffit à maitriser les applications directes de ses connaissances. Par exemple, connaitre la définition de la médiatrice ne garantit pas de savoir la tracer.",
    problemTwoTitle: "Les lacunes s’accumulent dans le temps",
    problemTwo: "L’enseignement des mathématiques repose sur les connaissances acquises les années précédentes. De légères lacunes sur un chapitre peuvent handicaper un élève sur la suite de sa progression.",
    solutionIntroOne: "Pour répondre à ces deux problématiques et rétablir une spirale de la réussite, nous avons conçu, chez Ummia, des séries de ",
    solutionIntroTwo: "questions flash :",
    solutionOneTitle: "Se focaliser sur les fondamentaux",
    solutionOne: "Chacune de nos questions est concise et met en lumière une connaissance fondamentale, comme une définition, une règle de calcul ou encore des propriétés.",
    solutionTwoTitle: "Travailler le passé, pour consolider le futur",
    solutionTwo: "Chaque série de question flash contient des questions de la classe actuelle de l'élève et des classes inférieures du même cycle. Officiellement, le CM1, le CM2 et la 6e forment le cycle 3 et la 5e, 4e, et 3e, le cycle 4.",
    solutionThreeTitle: "Répéter pour maitriser",
    solutionThree: "Pour transformer les bases en automatismes et aborder sereinement les contenus plus complexes en classe, nous recommandons de faire au moins deux séries de questions flash par jour.",
    solutionFourTitle: "Tomber mais avoir les moyens de se relever",
    solutionFour: "Chaque question est accompagnée de sa correction accessible à la fin du QCM, et chaque erreur est expliquée permettant ainsi à l'élève de s’auto-évaluer et d’immédiatement acquérir de nouveaux savoirs.",
}

// ---- ---- Team sub-screen
export const kAboutTeamTexts = {
    title: "Des enseignants passionnés de pédagogie pour accompagner vos enfants",
    intro: "Chez Ummia, nous sommes tous des professeurs de mathématiques certifiés de l’Éducation Nationale en activité ou l’ayant été. Nos compétences complémentaires nous permettent de vous proposer cet outil en ligne qui, on l’espère, vous accompagnera dans votre apprentissage des mathématiques le plus longtemps possible. Si vous souhaitez en savoir plus sur nos parcours, c’est en bas que ça se passe.",
    oneName: "Geoffroy Laboudigue",
    one: "Professeur de mathématiques chevronné avec plus de 10 ans d'expérience, c’est lui qui est à l’initiative d’Ummia. Il est également formateur d’enseignants à l’Éducation Nationale et auteur de manuels scolaires aux éditions Hachette. Il tient depuis ses débuts, la chaine youtube mathlab qui aide de nombreux élèves de France à progresser en mathématiques.",
    twoName: "Claire Ferragut",
    two: "Professeure de mathématiques depuis 15 ans. Elle a eu l’occasion d’enseigner dans de nombreux établissements, de la 6e à la terminale. Son expertise est précieuse pour développer le contenu de l’application qui prépare les élèves aux défis du lycée.",
    threeName: "Lucile Mandin",
    three: "Professeure de mathématiques depuis 8 ans, et également formatrice d’enseignants pour l’Éducation Nationale. Elle a su mettre à profit son sens de la pédagogie dans le cadre de projets innovants centrés autour des mathématiques. Lucile participe activement à l’écriture de scénarios de vidéos pour la chaîne Youtube ainsi que pour les ressources numériques des manuels scolaires aux éditions Hachette.",
    fourName: "Quentin Laboudigue",
    four: "Ingénieur de formation, il est très vite conquis par les problématiques d’apprentissage et de pédagogie. Il se lance alors dans deux années en tant que professeur de mathématiques au collège. Il décide ensuite de se consacrer à ses passions pour le graphisme et la technique pour devenir développeur d’applications web et mobile.",
}

// ---- ---- Contact sub-screen
export const kAboutContactTexts = {
    introOne: "Nous ne sommes actuellement pas assez nombreux pour répondre à des questions concernant le contenu de l'application mais nous restons disponibles pour des ",
    introTwo: "questions légales ",
    introThree: "à l'adresse suivante : ",
    mailAddress: "contact@ummia.fr"
}

// ---- System
export const kSystemTexts = {
    back: "Retour",
    cancel: "Annuler",
    leave: "Quitter",
    loremIpsumExtraShort: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    loremIpsumShort: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    loremIpsumLong: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    contactMailAddress: "contact@ummia.fr",
    understood: "J'ai compris",
    explanation: "Explication",
    trap: "À retenir",
    themes: "Thèmes",
    skills: "Savoir-faire",
    goodSelectedAnswer: "Tu as trouvé la bonne réponse",
    goodUnselectedAnswer: "Voici la bonne réponse",
    falseSelectedAnswer: "Tu as fait une erreur",
    falseUnselectedAnswer: "C'est une erreur",
    retry: "Réesayer"
}

