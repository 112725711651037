import {isNullOrUndefined} from "../../services/Validator";

function SystemDialogHeaderLayout({children}) {
    // Used in Dialogs to frame upper part of the dialog content

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className="w-full flex flex-col items-center space-y-8">
            {children}
        </div>
    );
}

// EXPORT
export default SystemDialogHeaderLayout