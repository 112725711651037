import packageJson from "../../../../package.json";

function HomeAppVersionNumber() {
    // Used in HomeDrawerBottomSection

    // BUILD
    return (
        <div className={"flex justify-center items-center"}>
            <span className={"text-[12px] text-greyLight5 font-normal"}>
                {packageJson.version}
            </span>
        </div>
    );
}

// EXPORT
export default HomeAppVersionNumber