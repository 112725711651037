import {computeProgressBarWidth} from "../../controllers/SystemLinearProgressBarController";


function SystemLinearProgressBar({progression, total}) {
    // Used through the app to display a progression

    // BUILD
    return(
        <div className="w-full border-primaryDark3 shadow-[inset_0px_0px_0px_1px_rgba(22,147,227,1)] rounded-full h-[13px]">
            <div className="bg-gradient-to-t from-primaryDark3 to-primaryLight1 h-[13px] rounded-full"
                 style={{width: computeProgressBarWidth(progression, total)}}>
            </div>
        </div>
    );
}

// EXPORT
export default SystemLinearProgressBar