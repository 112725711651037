import {renderBorderColor} from "../../controllers/SystemOptionProposalController";
import {get_nested} from "../../services/JsonHandler";
import {isNullOrUndefined} from "../../services/Validator";
import {MathJax, MathJaxContext} from "better-react-mathjax";

function SystemOptionProposalText({option}) {
    // Used through the app to display single option proposal text in all
    // mobile, tablet and desktop modes

    // PROPERTIES
    const optionProposal = get_nested(['proposal'], option, null)

    // BUILD
    if (isNullOrUndefined(optionProposal))
        return null
    return (
        <div className="w-full flex justify-center">
            <div className={`border-1 rounded-[8px] py-4 sm:py-6 px-8 sm:px-12 ${renderBorderColor(option?.isCorrect)}`}>
                <MathJaxContext>
                    <MathJax inline dynamic>
                        <span className="font-bold text-white text-[16px] sm:text-[18px]">
                            {optionProposal}
                        </span>
                    </MathJax>
                </MathJaxContext>
            </div>
        </div>
    );
}

// EXPORT
export default SystemOptionProposalText