import SystemDesktopTopBarLayout from "../../../layouts/SystemDesktopTopBarLayout";
import SystemFlashLogoImage from "../../../images/SystemFlashLogoImage";
import SystemDesktopFlashToHomeButton from "../../../buttons/SystemDesktopFlashToHomeButton";
import {FlashLogoTextSize} from "../../../../controllers/SystemFlashLogoTextController";

function ParameterDesktopTopBar({goBackToHome}) {
    // Used in ParameterStepScreen to display top bar in desktop or tablet
    // mode made of logo and back to home button

    // BUILD
    return (
        <SystemDesktopTopBarLayout>
            <SystemFlashLogoImage flashLogoSize={FlashLogoTextSize.XSMALL} />
            <SystemDesktopFlashToHomeButton goBackToHome={goBackToHome} />
        </SystemDesktopTopBarLayout>
    );
}

// EXPORT
export default ParameterDesktopTopBar