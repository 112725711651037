import SystemKnowledgeTypeText from "../../texts/SystemKnowledgeTypeText";
import SystemKnowledgeDefinitionText from "../../texts/SystemKnowledgeDefinitionText";

function KnowledgeDefinition({knowledge}) {
    // Used in SingleKnowledgeScreen to display
    // type and definition texts

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-4 justify-start items-start px-12 py-8 border-primaryLight3 border-[1px] rounded-[12px]"}>
            <SystemKnowledgeTypeText knowledgeType={knowledge.type} />
            <SystemKnowledgeDefinitionText knowledgeDefinition={knowledge.definition} />
        </div>
    );
}

// EXPORT
export default KnowledgeDefinition