import {isNullOrUndefined} from "../../services/Validator";
import SystemScreenInfoTitleText from "../texts/SystemScreenInfoTitleText";
import SystemScreenInfoSubtitleText from "../texts/SystemScreenInfoSubtitleText";
import SystemScreenErrorIcon from "../icons/SystemScreenErrorIcon";
import {SystemSecondaryCustomButton} from "../buttons/SystemCustomButton";
import {kSystemTexts} from "../../constants/texts";
import {kButtonStyles} from "../../constants/styles";

function SystemScreenError({error, retryCallback, extraClass}) {
    // Used through the app to display an error object made of
    // error icon, title (problem) and subtitle (reinsurance)

    // BUILD
    if (isNullOrUndefined(error))
        return null
    return (
        <div className={"animate-appear w-full grow flex flex-col justify-center items-center space-y-6 " + extraClass}>
            <SystemScreenErrorIcon />
            <SystemScreenInfoTitleText title={error.statement} />
            <SystemScreenInfoSubtitleText subtitle={error.reassurance} />
            {isNullOrUndefined(retryCallback)
                ? null
                : <SystemSecondaryCustomButton
                    label={kSystemTexts.retry}
                    isDisabled={false}
                    isLoading={false}
                    isFullWidth={false}
                    sizeStyle={kButtonStyles.mediumButtonStyle}
                    onButtonClick={() => retryCallback()}
                    extraClass={"mt-16"} />
            }
        </div>
    );
}

// EXPORT
export default SystemScreenError