import {kFlashImages} from "../../../../constants/images";
import {kFlashTexts} from "../../../../constants/texts";

function ParameterNoGradeSelected() {
    // Used in ParameterSubthemesContent to display
    // message when no Grade has been selected

    // BUILD
    return (
        <div className={"animate-appear w-full h-full flex flex-col space-y-12 justify-center items-center shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[0px] sm:rounded-[16px] px-16 mt-16 sm:mt-24"}>
            <div className={`h-[96px] min-h-[96px]`}>
                <img className={"object-contain h-full"}
                     src={kFlashImages.noGradeSelected}
                     alt="Ours avec stylo et cahier" />
            </div>
            <span className={"w-[400px] font-medium text-center text-[16px] text-white whitespace-pre-line"}>
                {kFlashTexts.noGradeSelected}
            </span>
        </div>
    );
}

// EXPORT
export default ParameterNoGradeSelected