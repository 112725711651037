import SystemCustomDivider from "../../dividers/SystemCustomDivider";
import HomeDrawerWelcomeText from "../../texts/homeScreen/HomeDrawerWelcomeText";

function HomeDrawerUserSection() {
    // Used in HomeDrawerUserSection to display welcome message
    // and user related actions

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-24"}>
            <HomeDrawerWelcomeText />
            <SystemCustomDivider color={"bg-greyLight5"} />
        </div>
    );
}

// EXPORT
export default HomeDrawerUserSection