import MobileRedirectScreen from "./MobileRedirectScreen";
import {Outlet} from "react-router";

function MetaLayoutScreen() {
    // Used in index.js as a global layout, making mobile redirection
    // available for all routes

    // BUILD
    return (
        <div className="App">
            <header className="App-header">
                <MobileRedirectScreen />
                <Outlet />
            </header>
        </div>
    );
}

// EXPORT
export default MetaLayoutScreen