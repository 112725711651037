
function HomeDrawerWelcomeText() {
    // Used in HomeDrawerUserSection to display top
    // welcome message in Drawer

    // BUILD
    return (
        <div className={"px-16"}>
            <span className={"text-[16px] text-greyLight11 font-medium"}>
                {"Bienvenue !"}
            </span>
        </div>
    );
}

// EXPORT
export default HomeDrawerWelcomeText