import ResultsCardHeaderDivider from "../../../dividers/flashScreens/resultsStepScreen/ResultsCardHeaderDivider";
import ResultsSummaryDivider from "../../../dividers/flashScreens/resultsStepScreen/ResultsSummaryDivider";
import ResultsScoreCardContentLayout
    from "../../../layouts/flashScreens/resultsStepScreen/ResultsScoreCardContentLayout";
import ResultsDetailedScores from "../../../others/flashScreens/resultsStepScreen/ResultsDetailedScores";
import ResultsScore from "../../../others/flashScreens/resultsStepScreen/ResultsScore";
import ResultsCardHeader from "../../../others/flashScreens/resultsStepScreen/ResultsCardHeader";

function ResultsDesktopScoreCard({scores, total}) {
    // Used in ResultsStepScreen to display flash score summary

    // BUILD
    return (
        <div className={"hidden h-[240px] min-h-[240px] w-[400px] min-w-[400px] sm:flex sm:flex-col bg-backgroundLight5 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px]"}>
            <ResultsCardHeader headerTitle={"Résultats"} />
            <ResultsCardHeaderDivider />
            <ResultsScoreCardContentLayout>
                <ResultsScore correctAnswers={scores[0]}
                              total={total}/>
                <ResultsSummaryDivider />
                <ResultsDetailedScores good={scores[0]}
                                       bad={scores[1]}
                                       neutral={scores[2]} />
            </ResultsScoreCardContentLayout>
        </div>
    );
}

// EXPORT
export default ResultsDesktopScoreCard