import {SystemPrimaryCustomButton} from "../../../buttons/SystemCustomButton";
import {kButtonStyles} from "../../../../constants/styles";

function QuestionsDesktopBottomBar({selectedOption, submitAnswer}) {
    // Used in QuestionsStepScreen to display bottom bar
    // in desktop mode

    // BUILD
    return (
        <div className={"w-[320px] h-[48px] min-h-[48px] hidden sm:flex justify-center px-12"}>
            <SystemPrimaryCustomButton
                label={selectedOption !== null ? "Valider ma réponse" : "Choisir une réponse"}
                isDisabled={selectedOption === null}
                isLoading={false}
                isFullWidth={true}
                sizeStyle={kButtonStyles.mediumButtonStyle}
                onButtonClick={() => submitAnswer(selectedOption)}
                extraClass={"w-full h-full"}/>
        </div>
    );
}

// EXPORT
export default QuestionsDesktopBottomBar