import ResultsCounterBadge from "../../../others/flashScreens/resultsStepScreen/ResultsCounterBadge";
import ResultsDetailedScoreItemText from "../../../texts/flashScreens/resultsStepScreen/ResultsDetailedScoreItemText";


function ResultsDetailedScoreItem({isGoodScore, score, label, color}) {
    // Used in ResultsDetailedScores to display one type of answer
    // counter and associated label

    // BUILD
    return (
        <div className="flex items-center justify-start space-x-12 h-[40px]">
            <ResultsCounterBadge color={color}
                                 score={score} />
            <ResultsDetailedScoreItemText isGoodScore={isGoodScore}
                                          label={label} />
        </div>
    );
}

// EXPORT
export default ResultsDetailedScoreItem