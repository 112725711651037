import {isNullOrUndefined} from "../../../../services/Validator";

function ResultsDesktopContentLayout({children}) {
    // Used in ResultsStepScreen to frame results screen content
    // in desktop mode

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"mt-32 w-[960px] min-h-[600px] hidden lg:flex justify-between"}>
            {children}
        </div>
    );
}

// EXPORT
export default ResultsDesktopContentLayout