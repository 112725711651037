import {isNullOrUndefined} from "../../../../services/Validator";

function ParameterContentLayout({children}) {
    // Used in ParameterStepScreen to frame screen content

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"w-[560px] md:w-[640px] lg:w-[800px] grow flex flex-col justify-start items-center"}>
            {children}
        </div>
    );
}

// EXPORT
export default ParameterContentLayout