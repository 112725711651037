import {isNullOrUndefined} from "../services/Validator";

// METHODS
// ---- Compute width based on progression and total
export const computeProgressBarWidth = (progression, total) => {
    if (isNullOrUndefined(progression) || isNullOrUndefined(total) || typeof progression !== "number" || typeof total !== "number" || !Number.isInteger(progression) || !Number.isInteger(total) || total <= 0) {
        return '0%'
    } else {
        if (progression >= 0 && progression <= total) {
            return `${Math.round(100*progression/total)}%`
        } else if (progression >= 0 && progression > total) {
            return '100%'
        } else {
            return '0%'
        }
    }
}