import SystemFullScreenCenteredLayout from "../layouts/SystemFullScreenCenteredLayout";
import {CircularProgress} from "@mui/material";

function SystemScreenLoader() {
    // Used through the app to display one single screen centered
    // circular loader

    // BUILD
    return (
        <SystemFullScreenCenteredLayout>
            <div className={'animate-appear'}>
                <CircularProgress size={"56px"} style={{'color' : 'white'}} />
            </div>
        </SystemFullScreenCenteredLayout>
    );
}

// EXPORT
export default SystemScreenLoader