import {kSystemTexts} from "../constants/texts";
import {isNullOrUndefined} from "../services/Validator";

// METHODS
// ---- Render question option judgment text color
export function renderOptionJudgmentColor(correctness) {
    switch (correctness) {
        case true:
            return "text-primary"
        case false:
            return "text-secondary"
        case null:
            return "text-secondary"
        default:
            return "text-greyLight7"
    }
}

// ---- Render question option judgement text
export function renderOptionJudgmentText(isCorrect, noAnswerWasSubmitted, isSelectedAnswer) {
    if (!isNullOrUndefined(noAnswerWasSubmitted) && noAnswerWasSubmitted) {
        return isCorrect ? kSystemTexts.goodUnselectedAnswer : kSystemTexts.falseUnselectedAnswer
    } else {
        // Case : One of the three options has been submitted
        if (isCorrect)
            return isSelectedAnswer ? kSystemTexts.goodSelectedAnswer : kSystemTexts.goodUnselectedAnswer
        return isSelectedAnswer ? kSystemTexts.falseSelectedAnswer : kSystemTexts.falseUnselectedAnswer
    }
}