import {useState} from "react";
import {isNullOrUndefined} from "../../../../services/Validator";
import {
    computeGradePickerImage, computeGradePickerSize
} from "../../../../controllers/flashScreens/parameterStepScreen/ParameterGradeBadgeItemController";

function ParameterGradeBadgeItem({grade, selectedGrade, setFlashGrade, gradePickerSize}) {
    // Used in ParameterTabletGradesList to display one single
    // Grade badge

    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    if (isNullOrUndefined(grade))
        return null
    return (
        <div className={`${computeGradePickerSize(gradePickerSize)} cursor-pointer`}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
             onClick={() => setFlashGrade(grade)} >
            <img className={"h-full object-contain"}
                 src={computeGradePickerImage(grade, selectedGrade, isHovered)}
                 alt={"Niveau"} />
        </div>
    );
}

// EXPORT
export default ParameterGradeBadgeItem