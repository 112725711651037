

// METHODS
// ---- Render border text color
export function renderBorderColor(isCorrect) {
    switch (isCorrect) {
        case true:
            return 'border-primary'
        case false:
            return 'border-secondary'
        default:
            return ''
    }
}