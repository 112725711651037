

function SystemCustomDivider({isVertical, color, extraClass}) {
    // Used through the app to separate sections vertically or horizontally

    // BUILD
    return(
        // eslint-disable-next-line no-useless-concat
        <div className={`${isVertical ? "h-full w-[1px] min-w-[1px]" : "w-full h-[1px] min-h-[1px]" } ${color ? color : "bg-white"}` + ' ' + extraClass}> </div>
    );
}


// EXPORT
export default SystemCustomDivider