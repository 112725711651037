import {isNullOrUndefined} from "../../services/Validator";

function SystemScreenErrorSubtitleText({text}) {
    // Used in SystemScreenErrorTexts to display error subtitle text
    // in all mobile, tablet and desktop modes

    // BUILD
    if(isNullOrUndefined(text))
        return null
    return (
        <div className={"w-full flex justify-center"}>
            <span className={"text-[16px] sm:text-[18px] text-center font-medium text-greyLight11"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default SystemScreenErrorSubtitleText