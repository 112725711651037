import {isNullOrUndefined} from "../../services/Validator";


function SystemModalTexts({title, subtitle}) {
    // Used in Modals through the app to display and explain
    // modal main call to action and consequences

    // BUILD
    if (isNullOrUndefined(title) && isNullOrUndefined(subtitle))
        return null
    return(
        <div className="flex flex-col space-y-8 text-center">
            <span className="text-white text-[16px] sm:text-[18px] font-semibold">{title ?? ""}</span>
            <span className="text-greyLight9 text-[14px] sm:text-[16px] font-medium">{subtitle ?? ""}</span>
        </div>
    );
}

// EXPORT
export default SystemModalTexts