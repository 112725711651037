import {useState} from "react";
import AboutDesktopContentLayout from "../components/layouts/aboutScreen/AboutDesktopContentLayout";
import AboutDesktopTabletPageTitleText from "../components/texts/aboutScreen/AboutDesktopTabletPageTitleText";
import AboutDesktopTabletContent from "../components/others/aboutScreen/AboutDesktopTabletContent";
import AboutDesktopTabBar from "../components/bars/aboutScreen/AboutDesktopTabBar";
import AboutTabletTopBar from "../components/bars/aboutScreen/AboutTabletTopBar";
import AboutDesktopTopBar from "../components/bars/aboutScreen/AboutDesktopTopBar";
import AboutTabletTabBar from "../components/bars/aboutScreen/AboutTabletTabBar";
import AboutDesktopLayout from "../components/layouts/aboutScreen/AboutDesktopLayout";

function AboutScreen() {
    // About Screen used in index.js to display about multiple sections
    // in desktop and tablet modes

    // PROPERTIES
    const [selectedTab, setSelectedTab] = useState(0)

    // BUILD
    return(
        <div className={"w-full h-[100svh] flex flex-col overflow-hidden bg-backgroundLight3"}>
             <AboutTabletTopBar />
             <AboutDesktopTopBar />
             <AboutDesktopLayout>
                 <AboutDesktopContentLayout>
                     <AboutDesktopTabletPageTitleText />
                     <AboutTabletTabBar selectedTab={selectedTab}
                                        setSelectedTab={setSelectedTab} />
                     <AboutDesktopTabBar selectedTab={selectedTab}
                                         setSelectedTab={setSelectedTab} />
                     <AboutDesktopTabletContent selectedTab={selectedTab}
                                                setSelectedTab={setSelectedTab} />
                 </AboutDesktopContentLayout>
            </AboutDesktopLayout>
        </div>
    );
}

// EXPORT
export default AboutScreen