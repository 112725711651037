import {kSystemIcons} from "../../../constants/icons";

function HomeDrawerIcon({onPressed}) {
    // Used in Home desktop and tablet top bars
    // to show menu icon to press to open drawer

    // BUILD
    return (
        <div className={"cursor-pointer flex items-center"}
             onClick={onPressed}>
            <i className={`material-icons text-[32px] text-greyLight11 hover:text-white`}>
                {kSystemIcons.menu}
            </i>
        </div>
    );
}

// EXPORT
export default HomeDrawerIcon