import {kHomeTexts} from "../../../constants/texts";

function HomeTitleText() {
    // Used in HomeShowCase to display
    // landing title text

    // BUILD
    return (
        <div className={"w-full text-center leading-tight"}>
            <span className={"text-white font-bold text-[32px] md:text-[36px] whitespace-pre-line"}>
                <div dangerouslySetInnerHTML={{__html: kHomeTexts.punchline}}/>
            </span>
        </div>
    );
}

// EXPORT
export default HomeTitleText