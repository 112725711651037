import SystemQuestionKnowledge from "../../SystemQuestionKnowledge";
import SystemOptionsCard from "../../../cards/SystemOptionsCard";
import {get_nested} from "../../../../services/JsonHandler";
import SystemQuestionPicture from "../../../images/SystemQuestionPicture";
import {useState} from "react";
import SystemZoomedPictureDialog from "../../../dialogs/SystemZoomedPictureDialog";
import CorrectionQuestionQueryText from "../../../texts/flashScreens/correctionStepScreen/CorrectionQuestionQueryText";
import {
    displayLandscapeOrSquare,
    displayPortrait
} from "../../../../controllers/flashScreens/questionsStepScreen/QuestionPictureController";
import CorrectionContentLayout from "../../../layouts/flashScreens/correctionStepScreen/CorrectionContentLayout";
import CorrectionOptionsLayout from "../../../layouts/flashScreens/correctionStepScreen/CorrectionOptionsLayout";

function CorrectionContent({question}) {
    // Used in CorrectionStepScreen

    // PROPERTIES
    // ---- Modal
    const [isPictureModalOpen, setIsPictureModalOpen] = useState(false)
    // ---- Question
    const questionQuery = get_nested(['query'], question, null)
    const questionKnowledge = get_nested(['levelA', 'levelAKnowledge'], question, null)
    const questionPicture = get_nested(['questionPicture'], question, null)
    const questionPictureUrl = get_nested(['questionPicture', 'filePath'], question, null)
    const questionPictureRatio = get_nested(['questionPicture', 'ratio'], question, null)

    // BUILD
    return (
        <div className={"overflow-auto bg-backgroundLight3 w-full grow flex flex-col items-center"}>
            <CorrectionContentLayout>
                {/** MODAL */}
                <SystemZoomedPictureDialog open={isPictureModalOpen}
                                           handleClose={() => setIsPictureModalOpen(false)}
                                           pictureUrl={questionPictureUrl}
                                           pictureRatio={questionPictureRatio}/>
                {/** CORE */}
                <CorrectionQuestionQueryText query={questionQuery} />
                {/*<SystemQuestionPicturePlaceholder pictureRatio={PictureRatio.LANDSCAPE}*/}
                {/*                                  isForMobile={false} />*/}
                {displayLandscapeOrSquare(questionPicture, questionPictureUrl, questionPictureRatio)
                    ? <SystemQuestionPicture pictureUrl={questionPictureUrl}
                                             pictureRatio={questionPictureRatio}
                                             isForMobile={false}
                                             openZoom={() => setIsPictureModalOpen(true)}/>
                    : null}
                <SystemQuestionKnowledge knowledge={questionKnowledge}/>
                <CorrectionOptionsLayout>
                    {displayPortrait(questionPicture, questionPictureUrl, questionPictureRatio)
                        ? <SystemQuestionPicture pictureUrl={questionPictureUrl}
                                                 pictureRatio={questionPictureRatio}
                                                 isForMobile={false}
                                                 openZoom={() => setIsPictureModalOpen(true)}/>
                        : null}
                    {/*<SystemQuestionPicturePlaceholder pictureRatio={PictureRatio.PORTRAIT}*/}
                    {/*                                  isForMobile={false} />*/}
                    <SystemOptionsCard question={question}/>
                </CorrectionOptionsLayout>
            </CorrectionContentLayout>
        </div>
    );
}

// EXPORT
export default CorrectionContent