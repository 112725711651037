import {isArrayNullUndefinedOrEmpty, isNullOrUndefined} from "../../../services/Validator";

// METHODS
// ---- Test if current levelA is in selectedLevelAs

function isLevelASelected(levelA, selectedLevelAs) {
    if (!isNullOrUndefined(levelA) && !isArrayNullUndefinedOrEmpty(selectedLevelAs)) {
        return selectedLevelAs?.indexOf(levelA) >= 0
    } else {
        return false
    }
}

// ---- Render question option style
export function renderLevelAStyle(levelA, selectedLevelAs, isHovered) {
    if (isLevelASelected(levelA, selectedLevelAs)) {
        return "text-primaryLight5"
    } else {
        return isHovered ? "text-greyLight11" : "text-greyLight9"
    }
}