import KnowledgesTabletTopBar from "../components/bars/knowledgesScreen/KnowledgesTabletTopBar";
import KnowledgesDesktopTopBar from "../components/bars/knowledgesScreen/KnowledgesDesktopTopBar";
import KnowledgesContent from "../components/others/knowledgesScreen/KnowledgesContent";
import KnowledgesLayout from "../components/layouts/knowledgesScreen/KnowledgesLayout";
import KnowledgesContentLayout from "../components/layouts/knowledgesScreen/KnowledgesContentLayout";

function KnowledgesScreen() {
    // Referenced in index.js as a root screen, used to display
    // list of LevelAs published Knowledges

    // BUILD
    return (
        <div className={"animate-appearSlow overflow-auto w-full h-[100svh] bg-backgroundLight3 flex flex-col justify-start items-center"}>
            <KnowledgesTabletTopBar />
            <KnowledgesDesktopTopBar />
            <KnowledgesLayout>
                <KnowledgesContentLayout>
                    <KnowledgesContent />
                </KnowledgesContentLayout>
            </KnowledgesLayout>
        </div>
    );
}

// EXPORT
export default KnowledgesScreen