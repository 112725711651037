
function ResultsCardHeader({headerTitle, children}) {
    // Used in Results Cards to display card header information
    // made of text and additional element (children)

    // BUILD
    return (
        <div className="w-full flex justify-between px-12 pt-12">
            <span className="text-greyLight9 text-[16px] font-medium">
                {headerTitle}
            </span>
            {children}
        </div>
    );
}

// EXPORT
export default ResultsCardHeader