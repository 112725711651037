import {kPlayStoreLink} from "../../../constants/links";
import {kSystemImages} from "../../../constants/images";

function MobileRedirectPlayStoreLinkImage() {
    // Used in MobileRedirectScreen to display a clickable Play Store
    // link

    // BUILD
    return (
        <a href={kPlayStoreLink} target="_blank" rel="noopener noreferrer">
            <div className={"flex w-[200px] items-center justify-center"}>
                <img className={"object-contain w-full"}
                     src={kSystemImages.getIsOnPlayStore}
                     alt="Disponible sur le on Play store" />
            </div>
        </a>
    );
}

// EXPORT
export default MobileRedirectPlayStoreLinkImage