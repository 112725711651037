import {kSystemIcons} from "../../constants/icons";
import {computeErrorContainerSize} from "../../controllers/flashScreens/questionsStepScreen/QuestionPictureController";

function SystemQuestionPictureError({pictureRatio}) {
    // Used in SystemQuestionPicture to display error
    // when fetching failed

    // BUILD
    return (
        <div className={`flex flex-col space-y-6 justify-start items-center py-8 px-16 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px] ${computeErrorContainerSize(pictureRatio)}`}>
            <i className={`material-icons text-[24px] lg:text-[32px] text-greyLight7`}>
                {kSystemIcons.error}
            </i>
            <span className={"text-[16px] lg:text-[18px] font-medium text-greyLight7 text-center"}>
                {"Une erreur est survenue lors de l'affichage de l'image"}
            </span>
        </div>
    );
}

// EXPORT
export default SystemQuestionPictureError