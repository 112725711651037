import {isNullOrUndefined} from "../../services/Validator";

function SystemDialogMainImage({imageUrl}) {
    // Used through the app to display centered top image
    // in dialogs

    // BUILD
    if (isNullOrUndefined(imageUrl))
        return null
    return (
        <div className={"flex h-[64px] sm:h-[72px] items-center justify-center"}>
            <img className={"object-contain w-full h-full"} src={imageUrl} alt="" />
        </div>
    );
}

// EXPORT
export default SystemDialogMainImage