import {MathJax, MathJaxContext} from "better-react-mathjax";
import {isNullOrUndefined} from "../../../../services/Validator";

function CorrectionQuestionQueryText({query}) {
    // Used through the app to display
    // Question query text in desktop, tablet and mobile modes

    // BUILD
    if (isNullOrUndefined(query))
        return null
    return (
        <div className={"w-full text-center px-24"}>
            <MathJaxContext>
                <MathJax inline dynamic>
                    <span className="whitespace-pre-line font-bold text-[20px] md:text-[22px] text-white">
                        {query ?? "Erreur"}
                    </span>
                </MathJax>
            </MathJaxContext>
        </div>
    );
}

// EXPORT
export default CorrectionQuestionQueryText