import Modal from "@mui/material/Modal";
import {kButtonStyles, kModalStyles} from "../../../../constants/styles";
import SystemDialogHeaderLayout from "../../../layouts/SystemDialogHeaderLayout";
import SystemDialogClosingCrossIcon from "../../../icons/SystemDialogClosingCrossIcon";
import SystemDialogMainImage from "../../../images/SystemDialogMainImage";
import SystemModalTexts from "../../../texts/SystemModalTexts";
import {SystemSecondaryCustomButton} from "../../../buttons/SystemCustomButton";
import {kSystemTexts} from "../../../../constants/texts";
import {
    renderScoreIceEmoji
} from "../../../../controllers/flashScreens/resultsStepScreen/ResultsScoreEmojiImageController";
import {
    computeResultsFlashAppreciation
} from "../../../../controllers/flashScreens/resultsStepScreen/ResultsFlashAppreciationController";


function ResultsAppreciationDialog({open, handleClose, successRatio}) {
    // Used in ResultsScore to show total score related appreciation
    // in a modal

    // BUILD
    return(
        <Modal open={open}
               onClose={handleClose}>
            <div className={kModalStyles.dialogStyle}>
                <SystemDialogHeaderLayout>
                    <SystemDialogClosingCrossIcon onIconPress={() => handleClose()} />
                    <SystemDialogMainImage imageUrl={renderScoreIceEmoji(successRatio)} />
                </SystemDialogHeaderLayout>
                <SystemModalTexts title={computeResultsFlashAppreciation(successRatio)} />
                <SystemSecondaryCustomButton
                    label={kSystemTexts.back}
                    isDisabled={false}
                    isLoading={false}
                    isFullWidth={true}
                    onButtonClick={() => handleClose()}
                    sizeStyle={kButtonStyles.mediumButtonStyle} />
            </div>
        </Modal>
    )
}

// EXPORT
export default ResultsAppreciationDialog