import SystemQuestionCardHeaderMainText from "../texts/SystemQuestionCardHeaderMainText";
import SystemQuestionCardHeaderSubText from "../texts/SystemQuestionCardHeaderSubText";
import SystemCustomDivider from "../dividers/SystemCustomDivider";

function SystemDraftQuestionCardHeader({subtheme, task}) {
    // Used in SystemDraftQuestionCard to display card header made
    // of subtheme, task and divider

    // BUILD
    return (
        <div className={"w-full flex flex-col"}>
            <div className={"flex items-center justify-between px-16 py-12"}>
                <SystemQuestionCardHeaderMainText subtheme={subtheme} />
                <SystemQuestionCardHeaderSubText task={task} />
            </div>
            <SystemCustomDivider color={'bg-greyLight5'} />
        </div>
    );
}

// EXPORT
export default SystemDraftQuestionCardHeader