import {formatDateTime} from "../helpers/DateTimeHelper";
import {record_page_view_metric} from "../api/pageViewMetric";
import {scanDeviceType} from "../helpers/ScreenHelper";

// Used to centralize PageViewMetric related controllers and operations

// METHODS
// ---- Record PageViewMetric for landing page visit
export function recordPageViewMetric(eventName) {
    // Defensive measure
    if (!eventName)
        return
    // Prepare
    const now = new Date()
    let data = {
        deviceType: scanDeviceType(),
        eventName: eventName,
        eventDate: formatDateTime(now)
    }
    // Perform
    record_page_view_metric(data)
        .then(() => {
            // console.log(res)
        }).catch((err) => {
            console.error(err)
    })
}