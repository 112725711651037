
function SystemScreenInfoSubtitleText({subtitle}) {
    // Used through the app to display screen info title
    // text

    // BUILD
    if (!subtitle)
        return null
    return (
        <h2 className={"text-[16px] text-greyLight9 font-medium text-center"}>
            {subtitle}
        </h2>
    );
}

// EXPORT
export default SystemScreenInfoSubtitleText