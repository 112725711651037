import {isNullOrUndefined} from "../../services/Validator";
import {MathJax, MathJaxContext} from "better-react-mathjax";

function SystemOptionExplanationText({explanationText}) {
    // Used through the app to display option explanation text
    // for all mobile, tablet and desktop modes

    // BUILD
    if (isNullOrUndefined(explanationText))
        return null
    return (
        <div className={"w-full"}>
            <MathJaxContext>
                <MathJax inline dynamic>
                    <span className={'whitespace-pre-line font-semibold text-white text-[16px] sm:text-[18px]'}>
                        {explanationText}
                    </span>
                </MathJax>
            </MathJaxContext>
        </div>
    );
}

// EXPORT
export default SystemOptionExplanationText