import ParameterGradesList from "../../../lists/flashScreens/parameterStepScreen/ParameterGradesList";
import {isArrayNullUndefinedOrEmpty} from "../../../../services/Validator";
import SystemScreenError from "../../../errors/SystemScreenError";
import {kSystemGradesError} from "../../../../constants/errors";
import ParameterSubthemesContent from "./ParameterSubthemesContent";
import {
    GradePickerSize
} from "../../../../controllers/flashScreens/parameterStepScreen/ParameterGradeBadgeItemController";

function ParameterCoreContent({grades,
                             selectedGrade,
                             setFlashGrade,
                             isSubthemesLoading,
                             subthemesError,
                             subthemes,
                             selectSubtheme,
                             selectedSubtheme,
                             levelAs,
                             selectedLevelAs,
                             toggleLevelA}) {
    // Used in ParameterStepScreen to display both
    // Grade and Subthemes pickers

    // BUILD
    if (isArrayNullUndefinedOrEmpty(grades))
        return <SystemScreenError error={kSystemGradesError} />
    return (
        <div className={"overflow-auto w-full grow h-[520px] min-h-[480px] flex flex-col"}>
            <ParameterGradesList grades={grades}
                                 selectedGrade={selectedGrade}
                                 setFlashGrade={setFlashGrade}
                                 gradePickerSize={GradePickerSize.MEDIUM}
                                 extraClass={"mt-24"} />
            <ParameterSubthemesContent selectedGrade={selectedGrade}
                                       isSubthemesLoading={isSubthemesLoading}
                                       subthemesError={subthemesError}
                                       subthemes={subthemes}
                                       selectSubtheme={selectSubtheme}
                                       selectedSubtheme={selectedSubtheme}
                                       levelAs={levelAs} selectedLevelAs={selectedLevelAs}
                                       toggleLevelA={toggleLevelA} />
        </div>
    );
}

// EXPORT
export default ParameterCoreContent