import {kKnowledgesImages} from "../../../constants/images";
import {kKnowledgesTexts} from "../../../constants/texts";

function KnowledgesNoGradeSelected() {
    // Used in KnowledgesCoreContent to display
    // message when no Grade has been selected

    // BUILD
    return (
        <div className={"animate-appear w-full grow flex flex-col space-y-12 justify-center items-center pt-24 pb-40"}>
            <div className={`h-[96px] min-h-[96px]`}>
                <img className={"object-contain h-full"}
                     src={kKnowledgesImages.noGradeSelected}
                     alt="Ours avec chapeau de diplômé" />
            </div>
            <span className={"w-[400px] font-medium text-center text-[16px] text-white whitespace-pre-line"}>
                {kKnowledgesTexts.noGradeSelected}
            </span>
        </div>
    );
}

// EXPORT
export default KnowledgesNoGradeSelected