import {isNullOrUndefined} from "../../../../services/Validator";
import ResultsScoreCard from "../../../cards/flashScreens/resultsStepScreen/ResultsScoreCard";
import ResultsGraphCard from "../../../cards/flashScreens/resultsStepScreen/ResultsGraphCard";

function ResultsTabletSummary({scores, total}) {
    // Used in ResultsStepScreen to display flash score summary
    // in tablet mode

    // BUILD
    if (isNullOrUndefined(scores) && isNullOrUndefined(total))
        return null;
    return (
        <div className={"w-full hidden sm:flex lg:hidden space-x-24 mt-24"}>
            <ResultsScoreCard scores={scores} total={total} />
            <ResultsGraphCard scores={scores} />
        </div>
    );
}

// EXPORT
export default ResultsTabletSummary