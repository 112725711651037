import {isArrayNullUndefinedOrEmpty, isNullOrUndefined} from "../services/Validator";
import {kDontKnowOption} from "../constants/objects";
import {get_nested} from "../services/JsonHandler";

// Used to handle SystemOptionsCard controllers

// METHODS
// ---- Base on submitted Option, get Option index to land to (0, 1 or 2)
export function getLandingOptionIndex(question) {
    // Prepare
    const selectedOption = get_nested(['selectedOption'], question, null)
    const selectedOptionProposal = get_nested(['selectedOption', 'proposal'], question, null)
    const options = get_nested(['options'], question, null)
    // Perform
    if (!isNullOrUndefined(selectedOption)) {
        if (selectedOptionProposal !== kDontKnowOption.proposal) {
            // Case : One of the options has been selected
            const index = options.findIndex((o) => o.id === selectedOption?.id)
            return index >= 0 ? index : 0
        } else {
            // Case : DontKnowOption has been selected => Redirect to Correct answer
            const index = options.findIndex((o) => o.isCorrect === true)
            return index >= 0 ? index : 0
        }
    } else {
        // By default, return first option index
        return 0
    }
}

// ---- Since options are shuffled by api, get index of the correct option
export function getCorrectOptionIndex(question) {
    // Defense
    if (isNullOrUndefined(question))
        return null
    // Prepare
    const options = get_nested(['options'], question, null)
    // Defense
    if (isArrayNullUndefinedOrEmpty(options))
        return null
    // Get index and return
    const index = options.findIndex((option) => get_nested(['isCorrect'], option, null) === true)
    return index >= 0 ? index : null
}