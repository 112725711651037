import {
    renderClosingIconPosition,
    renderClosingIconSize
} from "../../controllers/SystemBottomSheetClosingIconController";
import {kSystemIcons} from "../../constants/icons";

function SystemBottomSheetClosingIcon({onPressed, closingIconSize}) {
    // Used through the app to display closing bottom sheet modal
    // icon

    // BUILD
    return (
        <div className={`absolute flex justify-center items-center ${renderClosingIconPosition(closingIconSize)}`}>
            <i className={`cursor-pointer material-icons ${renderClosingIconSize(closingIconSize)} text-greyLight7 hover:text-greyLight9`}
               onClick={onPressed}>
                {kSystemIcons.cancel}
            </i>
        </div>
    );
}

// EXPORT
export default SystemBottomSheetClosingIcon