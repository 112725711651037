import {isNullOrUndefined} from "../../../services/Validator";

function PreviewContentLayout({children, extraClass}) {
    // Used in QuestionsStepScreen to frame screen content in
    // desktop mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"sm:w-[584px] md:w-[708px] lg:w-[960px] grow hidden sm:flex sm:flex-col justify-start items-center " + extraClass}>
            {children}
        </div>
    );
}

// EXPORT
export default PreviewContentLayout