import {isNullOrUndefined} from "../../../../services/Validator";

function CorrectionContentLayout({children}) {
    // Used in CorrectionStepScreen to frame content in
    // both desktop and tablet mode

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"bg-backgroundLight3 w-[536px] md:w-[672px] lg:w-[928px] grow flex flex-col justify-start items-center p-24"}>
            {children}
        </div>
    );
}

// EXPORT
export default CorrectionContentLayout