import ParameterSubthemeHeaderCounter from "../parameterStepScreen/ParameterSubthemeHeaderCounter";
import ParameterSelectedLevelAsCounter from "../parameterStepScreen/ParameterSelectedLevelAsCounter";
import {kSystemTexts} from "../../../../constants/texts";
import {ScreenWidth} from "../../../../enums/ScreenWidth";

function ParameterSubthemesContentHeader({subthemesCount, levelAsCount, selectedLevelAsCount}) {
    // Used in ParameterCoreContent to display Subthemes
    // LevelAs header

    // BUILD
    return (
        <div className={"bg-backgroundLight5 w-full flex items-center py-10 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-t-[16px]"}>
            <ParameterSubthemeHeaderCounter text={kSystemTexts.themes}
                                            count={subthemesCount}
                                            extraClass={"w-[266px] min-w-[266px] lg:w-[320px] lg:min-w-[320px] px-12 lg:px-16"} />
            <div className={"w-full px-12 lg:px-16 flex justify-between items-center"}>
                <ParameterSubthemeHeaderCounter text={kSystemTexts.skills}
                                                count={levelAsCount} />
                <ParameterSelectedLevelAsCounter count={selectedLevelAsCount}
                                                 screenWidth={ScreenWidth.LARGE} />
            </div>
        </div>
    );
}

// EXPORT
export default ParameterSubthemesContentHeader