import {isNullOrUndefined} from "../../../services/Validator";
import SystemScreenError from "../../errors/SystemScreenError";
import SystemNeoScreenLoader from "../../loaders/SystemNeoScreenLoader";
import KnowledgesNoGradeSelected from "./KnowledgesNoGradeSelected";
import KnowledgesTypedList from "../../lists/knowledgesScreen/KnowledgesTypedList";

function KnowledgesCoreContent({isKnowledgesLoading, knowledgesError, selectedGrade, knowledgesMap}) {
    // Used in KnowledgesContent to display Knowledges core content
    // which a list of typed Knowledges

    // BUILD
    if (!isNullOrUndefined(knowledgesError))
        return <SystemScreenError error={knowledgesError}
                                  extraClass={"pb-40"} />
    if (isKnowledgesLoading)
        return <SystemNeoScreenLoader />
    if (isNullOrUndefined(selectedGrade))
        return <KnowledgesNoGradeSelected />
    return (
        <div className={"animate-appearSlow overflow-auto bg-backgroundLight3 w-full grow flex flex-col space-y-24 px-16 py-24"}>
            {Object.keys(knowledgesMap).map((key, index) => (
                <KnowledgesTypedList key={index}
                                     type={key}
                                     amount={knowledgesMap[key].length}
                                     typedList={knowledgesMap[key]} />
            ))}
        </div>
    );
}

// EXPORT
export default KnowledgesCoreContent