import ThemesIntroText from "../../texts/themesScreen/ThemesIntroText";
import ThemesList from "../../lists/themesScreen/themesList";
import {get_themes} from "../../../api/theme";
import {isArrayNullUndefinedOrEmpty, isNullOrUndefined} from "../../../services/Validator";
import {kApiUnknownError, kEmptyResponseError} from "../../../constants/errors";
import {useCallback, useEffect, useState} from "react";
import SystemScreenError from "../../errors/SystemScreenError";
import SystemNeoScreenLoader from "../../loaders/SystemNeoScreenLoader";
import {kFakeThemes} from "../../../services/Faker";

function ThemesContent() {
    // Used in ThemesScreen to manage Themes related content

    // PROPERTIES
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [themes, setThemes] = useState([])

    // HOOKS
    // ---- Define function
    const getThemes = useCallback(() => {
        // <<<< ---- FAKER ---- -----
        if (process.env.REACT_APP_FAKER === "true") {
            setThemes(kFakeThemes)
            setIsLoading(false)
            setError(null)
            return
        }
        // ---- ---- FAKER ---- >>>>

        // Set loader on and clear potential error
        setIsLoading(true)
        setError(null)
        // Perform
        get_themes()
            .then((res) => {
                if (isNullOrUndefined(res) || isArrayNullUndefinedOrEmpty(res)) {
                    setError(kEmptyResponseError)
                } else {
                    setThemes(res)
                }
            }).catch((err) => {
            console.error(err)
            setError(kApiUnknownError)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [])
    // ---- Set hook
    useEffect(() => {
        getThemes()
    }, [getThemes])

    // BUILD
    if (!isNullOrUndefined(error))
        return <SystemScreenError error={error}
                                  retryCallback={() => getThemes()} />
    if (isLoading)
        return <SystemNeoScreenLoader />
    return (
        <div className={"animate-appearSlow w-full flex flex-col space-y-24"}>
            <ThemesIntroText />
            <ThemesList themes={themes} />
        </div>
    );
}

// EXPORT
export default ThemesContent