import {isNullOrUndefined} from "../../../../services/Validator";

function ParameterLayout({children}) {
    // Used in ParameterStepScreen to frame screen content

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"overflow-auto bg-backgroundLight3 w-full grow flex flex-col justify-start items-center"}>
            {children}
        </div>
    );
}

// EXPORT
export default ParameterLayout