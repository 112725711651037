import Modal from "@mui/material/Modal";
import {kModalStyles} from "../../constants/styles";
import {isNullOrUndefined} from "../../services/Validator";
import {useEffect, useState} from "react";
import SystemZoomedPictureError from "../errors/SystemZoomedPictureError";
import SystemZoomedPictureLoader from "../loaders/SystemZoomedPictureLoader";
import {
    computePictureUrl,
    computePreferredAxis,
    computeZoomedQuestionPictureSize
} from "../../controllers/flashScreens/questionsStepScreen/QuestionPictureController";

function SystemZoomedPictureDialog({open, handleClose, pictureUrl, pictureRatio}) {
    // Used in SystemQuestionPicture and to display
    // zoomed Question picture in dialog

    // PROPERTIES
    const [hasError, setHasError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [src, setSrc] = useState(null)

    // HOOKS
    useEffect(() => {
        if (!isNullOrUndefined(pictureUrl)) {
            // Switch loader on and clear potential error
            setIsLoading(true)
            setHasError(false)
            // Prepare
            const url = computePictureUrl(pictureUrl)
            // Execute
            fetch(url).then((res) => {
                res.blob().then((res) => {
                    setSrc(URL.createObjectURL(res))
                }).catch((err) => {
                    console.log(err)
                    setHasError(true)
                })
            }).catch((err) => {
                console.log(err)
                setHasError(true)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [pictureUrl])

    // BUILD
    return(
        <Modal open={open}
               onClose={handleClose}>
            <div className={kModalStyles.dialogZoomedPictureStyle}>
                {hasError || isNullOrUndefined(pictureUrl)
                    ? <SystemZoomedPictureError />
                    : isLoading
                        ? <SystemZoomedPictureLoader />
                        : <div className={`overflow-hidden rounded-[8px] ${computeZoomedQuestionPictureSize(pictureRatio, false)}`}>
                            <img src={src}
                                 alt="Illustration"
                                 className={`object-cover ${computePreferredAxis(pictureRatio)}`}
                                 onError={() => setHasError(true)}
                                 onLoad={() => setHasError(false)} />
                          </div>}
            </div>
        </Modal>
    )
}

// EXPORT
export default SystemZoomedPictureDialog