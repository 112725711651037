
// Used through the app to provide routes
// to navigate to

// APP ROUTES
export const routes = {
    // ---- Meta navigation
    authentication: "/auth",
    // ---- Main navigation
    home: "/",
    flash: "/flash",
    knowledges: "/connaissances",
    // ---- Side navigation
    about: "/a-propos",
    legals: "/mentions-legales",
    privacy: "/confidentialite",
    themes: "/programme",
    // ---- Off limit navigation
    preview: "/preview/:id"
}