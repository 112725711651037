import {kSystemIcons} from "../../../constants/icons";
import {useState} from "react";
import SingleKnowledgeScreen from "../../../screens/SingleKnowledgeScreen";
import {isNullOrUndefined} from "../../../services/Validator";
import {useResizeListener} from "../../../listeners/SystemResizeListener";

function KnowledgesItem({item}) {
    // Used in KnowledgesTypedList to display one single
    // Knowledge wit its keyword

    // PROPERTIES
    const [isModalOpen, setIsModalOpen] = useState(false)

    // HOOKS
    // ---- Eventually close modal during screen resize
    useResizeListener(() => setIsModalOpen(false))

    // BUILD
    if (isNullOrUndefined(item))
        return null
    return (
        <div className={"cursor-pointer bg-backgroundLight5 hover:bg-greyLight3 w-full flex items-center text-greyLight9 hover:text-white rounded-[8px] py-12 px-16"}
             onClick={() => openModal()}>
            {/** SINGLE KNOWLEDGE MODAL */}
            <SingleKnowledgeScreen open={isModalOpen}
                                   handleClose={() => setIsModalOpen(false)}
                                   knowledge={item} />
            {/** CONTENT */}
            <div className={"grow"}>
                <span className={"text-[18px] font-medium"}>
                    {item.keyword}
                </span>
            </div>
            <i className={`material-icons text-[24px]`}>
                {kSystemIcons.chevronRight}
            </i>
        </div>
    );

    // METHODS
    // ---- Open modal (necessary due to bug during closing)
    function openModal() {
        if (!isModalOpen)
            setIsModalOpen(true)
    }
}

// EXPORT
export default KnowledgesItem