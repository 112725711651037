import {useEffect} from "react";

// METHODS
// ---- Listen for screen resize and execute callback
export function useResizeListener(callback) {
    useEffect(() => {
        // Define action
        const handleResize = () => {
            if (typeof callback === "function") {
                if (window.innerWidth < 640) {
                    callback();
                }
            }
        };
        // Add listener
        window.addEventListener("resize", handleResize);
        // Perform
        handleResize();
        // Cleaning function
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [callback]); // Dépend de la fonction callback
}