import {kPreviewImages} from "../../../constants/images";
import {renderPreviewLogoTextSize} from "../../../controllers/previewScreen/PreviewLogoTextController";

function PreviewLogoTextImage({logoSize}) {
    // Used through the app to display flash logo image in desktop
    // mode which is the text version of flash logo

    // BUILD
    return (
        <div className={`animate-appear ${renderPreviewLogoTextSize(logoSize)} mb-4`}>
            <img className={"h-full w-full object-contain"}
                 src={kPreviewImages.logoText}
                 alt="Logo" />
        </div>
    );
}

// EXPORT
export default PreviewLogoTextImage