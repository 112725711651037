
// Used through the app to handle logo text component controllers

// PROPERTIES
export const LogoTextSize = {
    XSMALL: "xSmall",
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large"
}

// METHODS
// ---- Render logo text image size
export function renderLogoTextSize(logoTextSize) {
    switch(logoTextSize) {
        case LogoTextSize.XSMALL:
            return "h-[24px] min-h-[24px] max-h-[24px] w-[83px] min-w-[83px] max-w-[83px] mb-4"
        case LogoTextSize.SMALL:
            return "h-[32px] min-h-[32px] max-h-[32px] w-[110px] min-w-[110px] max-w-[110px] mb-6"
        case LogoTextSize.MEDIUM:
            return "h-[40px] min-h-[40px] max-h-[40px] w-[137px] min-w-[137px] max-w-[137px] mb-6"
        case LogoTextSize.LARGE:
            return "h-[48px] min-h-[48px] max-h-[48px] w-[165px] min-w-[165px] max-w-[165px] mb-6"
        default:
            return "h-[32px] min-h-[32px] max-h-[32px] w-[110px] min-w-[110px] max-w-[110px] mb-6"
    }
}