import {isNullOrUndefined} from "../../../../services/Validator";

function QuestionsTabletLayout({children}) {
    // Used in QuestionStepScreen to frame screen content in tablet mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"hidden sm:w-[584px] md:w-[708px] grow sm:flex sm:flex-col lg:hidden items-center justify-start space-y-24 pb-16"}>
            {children}
        </div>
    );
}

// EXPORT
export default QuestionsTabletLayout