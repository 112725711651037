
// CONSTANTS
// ---- flashScreen
export const kFlashQuestionsStatement = "Une erreur est survenue pendant le chargement des questions"
export const kFlashQuestionsReassurance = "Nous faisons notre possible pour résoudre le problème au plus vite"
export const kFlashSubthemesStatement = "Une erreur est survenue pendant le chargement des thèmes"
export const kFlashSubthemesReassurance = "Nous faisons notre possible pour résoudre le problème au plus vite"
// ---- knowledgesScreen
export const kKnowledgesStatement = "Aucune connaissance trouvée, une erreur est survenue"
export const kKnowledgesReassurance = "Nous faisons notre possible pour résoudre le problème au plus vite"
// ---- System
export const kSystemGradesStatement = "Une erreur est survenue pendant le chargement des niveaux"
export const kSystemGradesReassurance = "Nous faisons notre possible pour résoudre le problème au plus vite"
// ---- Api
// ---- ---- Unknown
export const kApiUnknownStatementText = "Une erreur serveur est survenue"
export const kApiUnknownReassuranceText = "Nous faisons notre possible pour résoudre le problème au plus vite"
// ---- ---- Empty response
export const kEmptyResponseStatementText = "Aucune donnée trouvée"
export const kEmptyResponseReassuranceText = "Nous allons faire au plus vite pour résoudre le problème"

// PROPERTIES
// ---- flashScreen
export const kFlashSubthemesError = {
    statement: kFlashSubthemesStatement,
    reassurance : kFlashSubthemesReassurance
}
export const kFlashQuestionsError = {
    statement: kFlashQuestionsStatement,
    reassurance : kFlashQuestionsReassurance
}

// ---- knowledgesScreen
export const kKnowledgesError = {
    statement: kKnowledgesStatement,
    reassurance : kKnowledgesReassurance
}

// ---- System
export const kSystemGradesError = {
    statement: kSystemGradesStatement,
    reassurance : kSystemGradesReassurance
}

// ---- Api
// ---- ---- Unknown
export const kApiUnknownError = {
    statement: kApiUnknownStatementText,
    reassurance: kApiUnknownReassuranceText
}
// ---- ---- Empty response
export const kEmptyResponseError = {
    statement: kEmptyResponseStatementText,
    reassurance: kEmptyResponseReassuranceText
}