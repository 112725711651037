import SystemDesktopTopBarLayout from "../../../layouts/SystemDesktopTopBarLayout";
import SystemFlashTopBarLogoLayout from "../../../layouts/SystemFlashTopBarLogoLayout";
import SystemFlashLogoImage from "../../../images/SystemFlashLogoImage";
import {FlashLogoTextSize} from "../../../../controllers/SystemFlashLogoTextController";
import SystemFlashSelectedGradeBadge from "../../../badges/SystemFlashSelectedGradeBadge";
import SystemDesktopTabletLeaveFlashButton from "../../../buttons/SystemDesktopTabletLeaveFlashButton";
import {kFlashTexts} from "../../../../constants/texts";
import SystemFlashTopTexts from "../../../texts/SystemFlashTopTexts";

function ResultsDesktopTopBar({selectedGrade, onLeaveFlashPressed}) {
    // Used in ResultsStepScreen to display top bar in
    // desktop mode

    // BUILD
    return (
        <SystemDesktopTopBarLayout>
            <SystemFlashTopBarLogoLayout>
                <SystemFlashLogoImage flashLogoSize={FlashLogoTextSize.XSMALL}/>
                <SystemFlashSelectedGradeBadge selectedGrade={selectedGrade} />
            </SystemFlashTopBarLogoLayout>
            <SystemFlashTopTexts title={kFlashTexts.resultsDesktopTopTitle}
                                 subtitle={kFlashTexts.resultsDesktopTopSubtitle}/>
            <SystemDesktopTabletLeaveFlashButton onLeaveFlashPressed={onLeaveFlashPressed} />
        </SystemDesktopTopBarLayout>
    );
}

// EXPORT
export default ResultsDesktopTopBar