import {kSystemIcons} from "../../../constants/icons";

function MobileRedirectPhoneIcon() {
    // Used in MobileRedirectScreen to display smartphone icon

    // BUILD
    return (
        <i className={`material-icons text-[48px] text-greyLight11`}>
            {kSystemIcons.smartphone}
        </i>
    );
}

// EXPORT
export default MobileRedirectPhoneIcon