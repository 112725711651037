import {isNullOrUndefined} from "../../../services/Validator";
import parse from 'html-react-parser';
import LegalsDesktopTabletTitleText from "../../texts/legalsScreen/LegalsDesktopTabletTitleText";
import LegalsDesktopTabletLastUpdateText from "../../texts/legalsScreen/LegalsDesktopTabletLastUpdateText";
import SystemScreenError from "../../errors/SystemScreenError";
import SystemNeoScreenLoader from "../../loaders/SystemNeoScreenLoader";
import {useCallback, useEffect, useState} from "react";
import {kFakeLegalContent} from "../../../services/Faker";
import {get_legal_content} from "../../../api/legalContent";
import {kApiUnknownError, kEmptyResponseError} from "../../../constants/errors";

function LegalsContent({legalContentKey}) {
    // Used in LegalsScreen to display legal content
    // in desktop and tablet modes

    // PROPERTIES
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [legalContent, setLegalContent] = useState(null)

    // HOOKS
    // ---- Triggered at rendering : get legalContent from api
    const getLegalContent = useCallback(() => {
        // <<<< ---- FAKER ---- -----
        if (process.env.REACT_APP_FAKER === "true") {
            setLegalContent(kFakeLegalContent)
            setIsLoading(false)
            setError(null)
            return
        }
        // ---- ---- FAKER ---- >>>>

        // Prepare
        setIsLoading(true)
        setError(null)
        // Execute
        get_legal_content(legalContentKey)
            .then((res) => {
                if (isNullOrUndefined(res) || isNullOrUndefined(res.content)) {
                    setError(kEmptyResponseError)
                } else {
                    setLegalContent(res)
                }
            }).catch((err) => {
            console.log(err)
            setError(kApiUnknownError)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [legalContentKey])
    useEffect(() => {
        getLegalContent()
    }, [getLegalContent])

    // BUILD
    if (!isNullOrUndefined(error))
        return <SystemScreenError error={error}
                                  retryCallback={() => getLegalContent()} />
    if (isLoading)
        return <SystemNeoScreenLoader />
    return (
        <div className={"animate-appearSlow w-full flex flex-col space-y-24"}>
            <div className={"w-full flex flex-col space-y-8 items-start leading-none"}>
                <LegalsDesktopTabletTitleText pageTitle={legalContent.title} />
                <LegalsDesktopTabletLastUpdateText updatedAt={legalContent.updatedAt} />
            </div>
            <div className={"legalContent"}>
                {parse(legalContent.content)}
            </div>
        </div>
    );
}

// EXPORT
export default LegalsContent