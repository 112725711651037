import {Checkbox} from "@mui/material";
import {useState} from "react";
import {isNullOrUndefined} from "../../../../services/Validator";
import {checkboxStyle} from "../../../../constants/styles";
import {
    renderLevelAStyle
} from "../../../../controllers/flashScreens/parameterStepScreen/ParameterLevelAItemController";

function ParameterLevelAItem({levelA, selectedLevelAs, toggleLevelA}) {
    // Used in LevelDesktopSchoolLevelsList to display one single
    // level card

    // PROPERTIES
    const[isHovered, setIsHovered] = useState(false)

    // BUILD
    if (isNullOrUndefined(levelA) || isNullOrUndefined(selectedLevelAs))
        return null
    return (
        <div className={`cursor-pointer w-full flex justify-between items-center pl-12 py-12 border-b-1 border-backgroundLight5`}
             onClick={() => toggleLevelA(levelA)}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}>
            <span className={`font-medium text-[16px] ${renderLevelAStyle(levelA, selectedLevelAs, isHovered)}`}>
                {levelA?.task ?? "Erreur"}
            </span>
            <Checkbox disableRipple={true}
                      sx={checkboxStyle}
                      checked={selectedLevelAs?.indexOf(levelA) >= 0}/>
        </div>
    );
}

// EXPORT
export default ParameterLevelAItem