import {api} from "./routes";
import {generatePath} from "react-router";
import {makeRequest, Methods} from "./request";

// List of methods triggering Grade related api calls

// ---- Get flash open levels
export function get_flash_grades() {
    const url = api.domain + generatePath(api.routes.flashGrades)
    return makeRequest(url, Methods.GET)
}