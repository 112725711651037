import {MathJax, MathJaxContext} from "better-react-mathjax";

function SystemKnowledgeDefinitionText({knowledgeDefinition}) {
    // Used in SystemQuestionKnowledge to display Knowledge
    // definition text to know by heart

    // BUILD
    return (
        <MathJaxContext>
            <MathJax inline dynamic>
                <span className={"whitespace-pre-line text-greyLight11 text-[16px] lg:text-[18px] font-medium sm:font-semibold mt-2"}>
                    {knowledgeDefinition}
                </span>
            </MathJax>
        </MathJaxContext>
    );
}

// EXPORT
export default SystemKnowledgeDefinitionText