import Modal from "@mui/material/Modal";
import {Slide} from "@mui/material";
import SystemBottomSheetClosingIcon from "../../components/icons/SystemBottomSheetClosingIcon";
import {ClosingIconSize} from "../../controllers/SystemBottomSheetClosingIconController";
import CorrectionGhostTopBar from "../../components/bars/flashScreens/correctionStepScreen/CorrectionGhostTopBar";
import CorrectionContent from "../../components/others/flashScreens/correctionStepScreen/CorrectionContent";

function CorrectionStepScreen({open, handleClose, question}) {
    // Used in ResultsQuestionItem component to show single question
    // correction

    // BUILD
    return(
        <Modal open={open}
               onClose={handleClose}>
            <Slide direction="up" in={open} mountOnEnter unmountOnExit>
                <div className="overflow-auto w-full h-[100svh] flex flex-col items-center justify-end outline-none">
                    <CorrectionGhostTopBar handleClose={handleClose} />
                    <div className={"relative overflow-auto w-full grow flex flex-col items-center rounded-t-[32px]"}>
                        <SystemBottomSheetClosingIcon onPressed={handleClose}
                                                      closingIconSize={ClosingIconSize.MEDIUM} />
                        <CorrectionContent question={question} />
                    </div>
                </div>
            </Slide>
        </Modal>
    );
}

// EXPORT
export default CorrectionStepScreen