import {isNullOrUndefined} from "../../../services/Validator";

function LegalsContentLayout({children}) {
    // Used in both Legals and Privacy screens to
    // frame content in both desktop and tablet modes

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"w-[560px] md:w-[640px] lg:w-[720px] grow flex flex-col justify-start items-center space-y-24 py-32"}>
            {children}
        </div>
    );
}

// EXPORT
export default LegalsContentLayout