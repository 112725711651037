import ThemesTabletTopBar from "../components/bars/themesScreen/ThemesTabletTopBar";
import ThemesDesktopTopBar from "../components/bars/themesScreen/ThemesDesktopTopBar";
import ThemesContent from "../components/others/themesScreen/ThemesContent";
import ThemesLayout from "../components/layouts/themesScreen/ThemesLayout";
import ThemesContentLayout from "../components/layouts/themesScreen/ThemesContentLayout";

function ThemesScreen() {
    // Referenced in index.js as a root screen, used to display
    // explanations about official program and Themes

    // BUILD
    return (
        <div className={"animate-appearSlow overflow-auto w-full h-[100svh] bg-backgroundLight3 flex flex-col justify-start items-center"}>
            <ThemesTabletTopBar />
            <ThemesDesktopTopBar />
            <ThemesLayout>
                <ThemesContentLayout>
                    <ThemesContent />
                </ThemesContentLayout>
            </ThemesLayout>
        </div>
    );
}

// EXPORT
export default ThemesScreen