
function SystemScreenErrorIcon() {
    // Used through the app to display error icon in
    // both mobile and desktop mode

    // BUILD
    return (
        <div className={"flex items-center"}>
            <i className={"material-icons text-[40px] text-secondary"}>
                error
            </i>
        </div>
    );
}

// EXPORT
export default SystemScreenErrorIcon