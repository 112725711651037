import SystemUmmiaLogoTextImage from "../../images/SystemUmmiaLogoTextImage";
import {LogoTextSize} from "../../../controllers/SystemUmmiaLogoTextController";
import SystemDesktopTopBarLayout from "../../layouts/SystemDesktopTopBarLayout";
import HomeDrawerIcon from "../../icons/homeScreen/HomeDrawerIcon";

function HomeDesktopTopBar ({openDrawer}) {
    // Used in HomeScreen to display screen top bar

    // BUILD
    return (
        <SystemDesktopTopBarLayout>
            <SystemUmmiaLogoTextImage logoTextSize={LogoTextSize.SMALL} />
            <HomeDrawerIcon onPressed={openDrawer} />
            {/*<HomeTopBarNavigation />*/}
        </SystemDesktopTopBarLayout>
    );
}

// EXPORT
export default HomeDesktopTopBar