import {isNullOrUndefined} from "../../../../services/Validator";

function ResultsQuestionItemLayout({children, setIsHovered, openModal}) {
    // Used in ResultsQuestionItem to frame question item

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"w-full h-[40px] min-h-[40px] flex items-center cursor-pointer px-16"}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
             onClick={() => openModal()}>
            {children}
        </div>
    );
}

// EXPORT
export default ResultsQuestionItemLayout