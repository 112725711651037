
// Used through the app to handle logo text component controllers

// PROPERTIES
export const PreviewLogoTextSize = {
    XXSMALL: "xxsmall",
    XSMALL: "xsmall",
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large"
}

// METHODS
// ---- Render logo text image size
export function renderPreviewLogoTextSize(previewLogoTextSize) {
    switch(previewLogoTextSize) {
        case PreviewLogoTextSize.XXSMALL:
            return "h-[16px] min-h-[16px] max-h-[16px] w-[72px] min-w-[72px] max-w-[72px]"
        case PreviewLogoTextSize.XSMALL:
            return "h-[24px] min-h-[24px] max-h-[24px] w-[108px] min-w-[108px] max-w-[108px]"
        case PreviewLogoTextSize.SMALL:
            return "h-[32px] min-h-[32px] max-h-[32px] w-[144px] min-w-[144px] max-w-[144px]"
        case PreviewLogoTextSize.MEDIUM:
            return "h-[40px] min-h-[40px] max-h-[40px] w-[180px] min-w-[180px] max-w-[180px]"
        case PreviewLogoTextSize.LARGE:
            return "h-[48px] min-h-[48px] max-h-[48px] w-[216px] min-w-[216px] max-w-[216px]"
        default:
            return "h-[32px] min-h-[32px] max-h-[32px] w-[144px] min-w-[144px] max-w-[144px]"
    }
}