import SystemCustomDivider from "./SystemCustomDivider";

function SystemExplanationsDivider() {
    // Used through the app to announce a section with
    // a textual divider

    // BUILD
    return (
        <div className={"w-full flex items-center"}>
            <SystemCustomDivider color={"bg-greyLight3"} />
            <span className={"font-normal text-[12px] sm:text-[14px] text-greyLight5 px-12 leading-none mb-2 sm:mb-4"}>
                {'Explications'}
            </span>
            <SystemCustomDivider color={"bg-greyLight3"} />
        </div>
    );
}

// EXPORT
export default SystemExplanationsDivider