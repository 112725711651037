import {kSystemIcons} from "../../constants/icons";

function SystemCorrectOptionIcon({isSelected}) {
    // Used in SystemOptionsCardHeaderItem to signal correct option

    // BUILD
    return (
        <div className={"w-[14px] min-w-[14px] h-[24px] min-h-[24px] leading-none flex items-center justify-center"}>
            <i className={`material-icons text-[24px] ${isSelected ? "text-white" : "text-greyLight7"}`}>
                {kSystemIcons.bolt}
            </i>
        </div>
    );
}

// EXPORT
export default SystemCorrectOptionIcon