import {kThemesTexts} from "../../../constants/texts";

function ThemesIntroText() {
    // Used in ThemesScreen to display intro text
    // about program

    // BUILD
    return (
        <div>
            <span className={"text-[16px] lg:text-[18px] text-greyLight11 font-semibold"}>
                {kThemesTexts.introText}
            </span>
        </div>
    );
}

// EXPORT
export default ThemesIntroText