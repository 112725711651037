import {kHomeTexts} from "../../../constants/texts";
import {kSystemIcons} from "../../../constants/icons";
import {routes} from "../../../constants/routes";
import HomeNavigationItem from "../../items/homeScreen/HomeNavigationItem";

function HomeDrawerSideNavigation() {
    // Used in HomeDrawer to display side navigation
    // items

    // PROPERTIES
    const sideNavigationItems = [
        {
            name: kHomeTexts.navAbout,
            icon: kSystemIcons.about,
            route: routes.about,
        },
        {
            name: kHomeTexts.navThemes,
            icon: kSystemIcons.balance,
            route: routes.themes,
        },
        {
            name: kHomeTexts.navLegals,
            icon: kSystemIcons.assignment,
            route: routes.legals,
        },
        {
            name: kHomeTexts.navPrivacy,
            icon: kSystemIcons.lock,
            route: routes.privacy,
        }
    ]

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-12 px-12 mt-16"}>
            {sideNavigationItems.map((item, index) => (
                <HomeNavigationItem key={index}
                                    item={item}
                                    isSideNavigation={true} />
            ))}

        </div>
    );
}

// EXPORT
export default HomeDrawerSideNavigation