import {renderPreviewLogoTextSize} from "../../../controllers/previewScreen/PreviewLogoTextController";
import {kPreviewImages} from "../../../constants/images";

function PreviewLogoImage({logoSize}) {
    // Used through the app to display flash logo image in desktop
    // mode which is the text version of flash logo

    // BUILD
    return (
        <div className={`animate-appear ${renderPreviewLogoTextSize(logoSize)} mb-4`}>
            <img className={"h-full object-contain"}
                 src={kPreviewImages.logo}
                 alt="Loupe" />
        </div>
    );
}

// EXPORT
export default PreviewLogoImage