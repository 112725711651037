
function SystemScreenInfoTitleText({title}) {
    // Used through the app to display screen info title
    // text

    // BUILD
    if (!title)
        return null
    return (
        <h1 className={"text-[18px] text-white font-semibold text-center"}>
            {title}
        </h1>
    );
}

// EXPORT
export default SystemScreenInfoTitleText