import Modal from "@mui/material/Modal";
import SystemDialogHeaderLayout from "../../../layouts/SystemDialogHeaderLayout";
import SystemDialogClosingCrossIcon from "../../../icons/SystemDialogClosingCrossIcon";
import SystemDialogMainImage from "../../../images/SystemDialogMainImage";
import SystemModalTexts from "../../../texts/SystemModalTexts";
import {kFlashTexts, kSystemTexts} from "../../../../constants/texts";
import {SystemSecondaryCustomButton} from "../../../buttons/SystemCustomButton";
import {kButtonStyles} from "../../../../constants/styles";
import {kSystemImages} from "../../../../constants/images";

function QuestionDontKnowDialog({open, handleClose}) {
    // Used in QuestionsStepScreen to display explanations about
    // user not being able to respond to the corresponding question

    // BUILD
    return(
        <Modal open={open} onClose={handleClose}>
            <div className={"w-[280px] sm:w-[400px] flex flex-col items-center space-y-16 sm:space-y-24 bg-backgroundLight3 p-16 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] rounded-[16px]"}>
                <SystemDialogHeaderLayout>
                    <SystemDialogClosingCrossIcon onIconPress={() => handleClose()}/>
                    <SystemDialogMainImage imageUrl={kSystemImages.iceError}
                                           alt="Je ne sais pas"/>
                </SystemDialogHeaderLayout>
                <SystemModalTexts title={kFlashTexts.dontKnowTitle}
                                  subtitle={kFlashTexts.dontKnowSubtitle} />
                <SystemSecondaryCustomButton
                    label={kSystemTexts.understood}
                    isDisabled={false}
                    isLoading={false}
                    isFullWidth={true}
                    onButtonClick={() => handleClose()}
                    sizeStyle={kButtonStyles.mediumButtonStyle} />
            </div>
        </Modal>
    );
}

// EXPORT
export default QuestionDontKnowDialog