import {isNullOrUndefined} from "../../../services/Validator";
import {renderKnowledgeType} from "../../../controllers/SystemQuestionKnowledgeController";

function KnowledgesTypeText({type, amount}) {
    // Used in KnowledgesTypedList to display knowledges
    // sub list type text

    // PROPERTIES
    const knowledgeTypeObject = renderKnowledgeType(type, amount);

    // BUILD
    if (isNullOrUndefined(type))
        return null
    return (
        <div className={"flex justify-start items-center space-x-8"}>
            <i className="material-icons text-[20px]">
                {knowledgeTypeObject.icon}
            </i>
            <div className={"flex justify-start items-center space-x-4"}>
              <span className={"text-greyLight11 font-semibold text-[20px]"}>
                  {knowledgeTypeObject.title}
              </span>
                <span className={"text-greyLight5 font-normal text-[18px]"}>
                  {"(" + amount + ")"}
              </span>
            </div>

        </div>
    );
}

// EXPORT
export default KnowledgesTypeText