import SystemOptionsCardHeaderItem from "../items/SystemOptionsCardHeaderItem";


function SystemOptionsCardHeader({correctOptionIndex, selectedTab, setSelectedTab}) {
    // Used in SystemOptionsCard to display header in
    // question options card

    // BUILD
    return (
        <div className="w-full flex justify-between">
            {_renderOptionsButtonList()}
        </div>
    );

    // RENDER
    // ---- RENDER : Options list header
    function _renderOptionsButtonList() {
        const list = [];
        for (let i = 0; i <= 2; i++) {
            list.push(<SystemOptionsCardHeaderItem key={i}
                                                   index={i}
                                                   correctOptionIndex={correctOptionIndex}
                                                   isSelected={selectedTab === i}
                                                   pressOption={() => setSelectedTab(i)} />
            );
        }
        return list;
    }
}

// EXPORT
export default SystemOptionsCardHeader