import {isNullOrUndefined} from "../../../services/Validator";
import {ScreenWidth} from "../../../enums/ScreenWidth";
// Used in ParameterSelectedLevelAsCounter to handle
// component controllers

// METHODS
// ---- Compute text regarding count and screen width
export function computeLevelAsCounterText(count, screenWidth) {
    if (!isNullOrUndefined(count)) {
        if (screenWidth === ScreenWidth.LARGE) {
            return `${count} ${count > 1 ? "sélectionnés" : "sélectionné"}`
        } else {
            return `${count}`
        }
    } else {
        return null
    }
}