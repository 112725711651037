import {isNullOrUndefined} from "../../services/Validator";

function SystemScreenErrorLayout({children}) {
    // Used through the app to frame screen error
    // contents

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"animate-appear w-full sm:w-[480px] sm:min-w-[480px] flex flex-col justify-start items-center px-16 sm:px-0 space-y-24"}>
            {children}
        </div>
    );
}

// EXPORT
export default SystemScreenErrorLayout