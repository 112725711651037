
// Used through the app to list a set of keys, and thus reduce the
// risk of typos

// PROPERTIES
// ---- LegalContent
export const kLegalContentKeys = {
    legalMentions: 'legal_mentions',
    privacyPolicy: 'privacy_policy',
    termsOfUse: 'terms_of_use'
}
// ---- System
export const kSystemKeys = {
    flash: "flash",
}