import SystemTabletTopBarLayout from "../../../layouts/SystemTabletTopBarLayout";
import SystemFlashTopBarLogoLayout from "../../../layouts/SystemFlashTopBarLogoLayout";
import SystemFlashLogoImage from "../../../images/SystemFlashLogoImage";
import SystemFlashSelectedGradeBadge from "../../../badges/SystemFlashSelectedGradeBadge";
import {FlashLogoTextSize} from "../../../../controllers/SystemFlashLogoTextController";
import SystemDesktopTabletLeaveFlashButton from "../../../buttons/SystemDesktopTabletLeaveFlashButton";
import {kFlashTexts} from "../../../../constants/texts";
import SystemFlashTopTexts from "../../../texts/SystemFlashTopTexts";

function ResultsTabletTopBar({selectedGrade, onLeaveFlashPressed}) {
    // Used in ResultsStepScreen to display top bar in tablet mode

    // BUILD
    return (
        <SystemTabletTopBarLayout>
            <SystemFlashTopBarLogoLayout>
                <SystemFlashLogoImage flashLogoSize={FlashLogoTextSize.XXSMALL} />
                <SystemFlashSelectedGradeBadge selectedGrade={selectedGrade} />
            </SystemFlashTopBarLogoLayout>
            <SystemFlashTopTexts title={kFlashTexts.resultsTabletTopTitle}
                                 subtitle={kFlashTexts.resultsTabletTopSubtitle} />
            <SystemDesktopTabletLeaveFlashButton onLeaveFlashPressed={onLeaveFlashPressed} />
        </SystemTabletTopBarLayout>
    );
}

// EXPORT
export default ResultsTabletTopBar