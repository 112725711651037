
// Used to provide DateTime related helping functions

// ---- Format DateTime to yyyy/mm/dd hh:mm:ss format
export function formatDateTime(date) {
    // Defensive
    if (!(date instanceof Date))
        return null

    // Format
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month start at 0 => Add 1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Return
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// ---- Format ISO date to DD/MM/YY format
export function formatIsoDateToDDMMYY(isoDate) {

    // Format
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0'); // Ajoute un zéro devant si nécessaire
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés de 0 à 11
    const year = String(date.getFullYear()).slice(-2); // Prend les deux derniers chiffres de l'année

    // Return
    return `${day}/${month}/${year}`;
}