import {useState} from "react";
import SystemLeaveFlashDialog from "../../components/dialogs/SystemLeaveFlashDialog";
import {isArrayNullUndefinedOrEmpty} from "../../services/Validator";
import SystemExistingFlashSnackBar from "../../components/snackbars/SystemExistingFlashSnackBar";
import {useResizeListener} from "../../listeners/SystemResizeListener";
import QuestionsTabletTopBar from "../../components/bars/flashScreens/questionsStepScreen/QuestionsTabletTopBar";
import QuestionsDesktopTopBar from "../../components/bars/flashScreens/questionsStepScreen/QuestionsDesktopTopBar";
import QuestionsTabletBottomBar from "../../components/bars/flashScreens/questionsStepScreen/QuestionsTabletBottomBar";
import QuestionsDesktopBottomBar
    from "../../components/bars/flashScreens/questionsStepScreen/QuestionsDesktopBottomBar";
import QuestionDontKnowDialog from "../../components/dialogs/flashScreens/questionsStepScreen/QuestionDontKnowDialog";
import QuestionsTabletLayout from "../../components/layouts/flashScreens/questionsStepScreen/QuestionsTabletLayout";
import QuestionsDesktopLayout from "../../components/layouts/flashScreens/questionsStepScreen/QuestionsDesktopLayout";
import QuestionsCard from "../../components/others/flashScreens/questionsStepScreen/QuestionsCard";

function QuestionsStepScreen({selectedGrade,
                              questions,
                              questionIndex,
                              selectedOption,
                              setSelectedOption,
                              submitAnswer,
                              onLeaveFlashPressed,
                              setIsLeaveModalOpen,
                              isLeaveModalOpen,
                              leaveFlash,
                              isSnackbarOpen,
                              handleClose}) {
    // Used in FlashScreen Component to display series of questions

    // PROPERTIES
    const [openDontKnowDialog, setOpenDontKnowDialog] = useState(false)
    const totalQuestions = questions?.length
    const currentQuestionIndex = questionIndex + 1

    // HOOKS
    // ---- Eventually close modal during screen resize
    useResizeListener(() => setOpenDontKnowDialog(false))

    // BUILD
    if (isArrayNullUndefinedOrEmpty(questions))
        return null
    return(
      <div className="overflow-auto animate-appear w-full h-[100svh] bg-backgroundLight3 flex flex-col items-center justify-start">
          {/** ---- SNACKBAR */}
          <SystemExistingFlashSnackBar open={isSnackbarOpen}
                                       handleClose={handleClose} />
          {/** ---- MODAL */}
          <SystemLeaveFlashDialog open={isLeaveModalOpen}
                                  handleClose={() => setIsLeaveModalOpen(false)}
                                  leaveFlash={leaveFlash} />
          <QuestionDontKnowDialog open={openDontKnowDialog}
                                  handleClose={() => setOpenDontKnowDialog(false)} />
          {/** TOP BAR */}
          <QuestionsTabletTopBar onLeaveFlashPressed={onLeaveFlashPressed}
                                 selectedGrade={selectedGrade}
                                 currentQuestion={currentQuestionIndex}
                                 totalQuestion={totalQuestions} />
          <QuestionsDesktopTopBar onLeaveFlashPressed={onLeaveFlashPressed}
                                  selectedGrade={selectedGrade}
                                  currentQuestion={currentQuestionIndex}
                                  totalQuestion={totalQuestions} />
          {/** CONTENT */}
          <div className={"overflow-auto bg-backgroundLight3 w-full grow flex flex-col items-center"}>
              {/** ---- TABLET */}
              <QuestionsTabletLayout>
                  <QuestionsCard question={questions[questionIndex]}
                                 optionSelected={selectedOption}
                                 setOptionSelected={setSelectedOption}
                                 setOpenDontKnowDialog={setOpenDontKnowDialog} />
                  <QuestionsTabletBottomBar selectedOption={selectedOption}
                                            submitAnswer={submitAnswer} />
              </QuestionsTabletLayout>
              {/** ---- DESKTOP */}
              <QuestionsDesktopLayout>
                  <QuestionsCard question={questions[questionIndex]}
                                 optionSelected={selectedOption}
                                 setOptionSelected={setSelectedOption}
                                 setOpenDontKnowDialog={setOpenDontKnowDialog} />
                  <QuestionsDesktopBottomBar selectedOption={selectedOption}
                                             submitAnswer={submitAnswer} />
              </QuestionsDesktopLayout>
          </div>
      </div>
    );
}

// EXPORT
export default QuestionsStepScreen