import {generatePath} from "react-router";
import {api} from "./routes";
import {makeRequest, Methods} from "./request";

// List of methods triggering themes related api calls

// ---- Get themes
export function get_themes() {
    const url = api.domain + generatePath(api.routes.themes)
    return makeRequest(url, Methods.GET, null)
}