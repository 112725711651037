import SystemQuestionCardQueryText from "../texts/SystemQuestionCardQueryText";
import {get_nested} from "../../services/JsonHandler";
import {isNullOrUndefined} from "../../services/Validator";
import SystemDraftQuestionCardOptionsList from "../lists/SystemDraftQuestionCardOptionsList";
import SystemDraftQuestionCardHeader from "../others/SystemDraftQuestionCardHeader";


function SystemDraftQuestionCard({question, selectedOption, setSelectedOption}) {
    // Used through the app to display a card made of Question info, statement
    // and options list in all mobile, tablet and desktop modes

    // PROPERTIES
    const questionSubtheme = get_nested(['levelA', 'subtheme', 'name'], question, null)
    const questionTask = get_nested(['levelA', 'task'], question, null)
    const questionQuery = get_nested(['query'], question, null)
    const questionOptions = get_nested(['options'], question, null)

    // BUILD
    if (isNullOrUndefined(question) || isNullOrUndefined(questionQuery))
        return null;
    return (
        <div className={"w-full bg-backgroundLight5 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px] flex flex-col"}>
            <SystemDraftQuestionCardHeader subtheme={questionSubtheme}
                                           task={questionTask} />
            <SystemQuestionCardQueryText query={questionQuery} />
            <SystemDraftQuestionCardOptionsList options={questionOptions}
                                                selectedOption={selectedOption}
                                                setSelectedOption={setSelectedOption} />
        </div>
    );
}

// EXPORT
export default SystemDraftQuestionCard