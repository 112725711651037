import {api} from "./routes";
import {generatePath} from "react-router";
import {makeRequest, Methods} from "./request";

// Used to list LegalContent related api calls

// METHODS
export function get_legal_content(key) {
    const url = api.domain + generatePath(api.routes.legalContent, {key})
    return makeRequest(url, Methods.GET, null)
}