import {get_nested} from "../../../../services/JsonHandler";
import {isNullOrUndefined} from "../../../../services/Validator";
import SystemQuestionPicture from "../../../images/SystemQuestionPicture";
import SystemZoomedPictureDialog from "../../../dialogs/SystemZoomedPictureDialog";
import {useState} from "react";
import QuestionsQueryText from "../../../texts/flashScreens/questionsStepScreen/QuestionsQueryText";
import {
    displayLandscapeOrSquare, displayPortrait
} from "../../../../controllers/flashScreens/questionsStepScreen/QuestionPictureController";
import QuestionsCardContentLayout from "../../../layouts/flashScreens/questionsStepScreen/QuestionsCardContentLayout";
import QuestionsOptionsList from "../../../lists/flashScreens/questionsStepScreen/QuestionsOptionsList";

function QuestionsCard({ question,
                         optionSelected,
                         setOptionSelected,
                         setOpenDontKnowDialog}) {
    // Used in QuestionsStepScreen to display question content in a card
    // in desktop and tablet format

    // PROPERTIES
    // ---- Modal
    const [isPictureModalOpen, setIsPictureModalOpen] = useState(false)
    // ---- Question
    const questionOptions = get_nested(['options'], question, null)
    const questionStatement = get_nested(['query'], question, null)
    const questionPicture = get_nested(['questionPicture'], question, null)
    const questionPictureUrl = get_nested(['questionPicture', 'filePath'], question, null)
    const questionPictureRatio = get_nested(['questionPicture', 'ratio'], question, null)

    // BUILD
    if (isNullOrUndefined(question))
        return null;
    return (
        <div className={"overflow-auto w-full grow flex flex-col justify-start items-center"}>
            {/** MODAL */}
            <SystemZoomedPictureDialog open={isPictureModalOpen}
                                       handleClose={() => setIsPictureModalOpen(false)}
                                       pictureUrl={questionPictureUrl}
                                       pictureRatio={questionPictureRatio}/>
            {/** CORE */}
            <QuestionsQueryText query={questionStatement}/>
            {/*<SystemQuestionPicturePlaceholder pictureRatio={PictureRatio.LANDSCAPE}*/}
            {/*                                  isForMobile={false} />*/}
            {displayLandscapeOrSquare(questionPicture, questionPictureUrl, questionPictureRatio)
                ? <SystemQuestionPicture pictureUrl={questionPictureUrl}
                                         pictureRatio={questionPictureRatio}
                                         isForMobile={false}
                                         openZoom={() => setIsPictureModalOpen(true)}
                                         extraClass={'mt-24'} />
                : null}
            <QuestionsCardContentLayout>
                {displayPortrait(questionPicture, questionPictureUrl, questionPictureRatio)
                    ? <SystemQuestionPicture pictureUrl={questionPictureUrl}
                                             pictureRatio={questionPictureRatio}
                                             isForMobile={false}
                                             openZoom={() => setIsPictureModalOpen(true)}/>
                    : null}
                {/*<SystemQuestionPicturePlaceholder pictureRatio={PictureRatio.PORTRAIT}*/}
                {/*                                  isForMobile={false} />*/}
                <QuestionsOptionsList options={questionOptions}
                                      optionSelected={optionSelected}
                                      setOptionSelected={setOptionSelected}
                                      onDontKnowPress={() => setOpenDontKnowDialog(true)}/>
            </QuestionsCardContentLayout>
        </div>
    );
}

// EXPORT
export default QuestionsCard