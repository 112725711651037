import {SystemSecondaryCustomButton} from "../../../buttons/SystemCustomButton";
import {kFlashTexts} from "../../../../constants/texts";
import {kButtonStyles} from "../../../../constants/styles";

function CorrectionMobileBottomBar({goBackToResults}) {
    // Used in CorrectionStepScreen to display sticky bottom app bar
    // with "go back to results" button

    // BUILD
    return (
        <div className={"w-full h-[64px] min-h-[64px] flex sm:hidden justify-center bg-backgroundLight3 p-12"}>
            <SystemSecondaryCustomButton
                label={kFlashTexts.back}
                isDisabled={false}
                isLoading={false}
                isFullWidth={true}
                sizeStyle={kButtonStyles.mediumButtonStyle}
                extraClass={"w-full h-full"}
                onButtonClick={() => goBackToResults()} />
        </div>
    );
}

// EXPORT
export default CorrectionMobileBottomBar