import SystemCustomDivider from "../../SystemCustomDivider";

function ResultsSummaryDivider() {
    // Used in ResultsSummary to separate numbered score and
    // detailed score

    // BUILD
    return (
        <div className={"h-full py-16 pl-4 pr-10 mr-6"}>
            <SystemCustomDivider isVertical={true}
                                 color={"bg-greyLight5"} />
        </div>
    );
}

// EXPORT
export default ResultsSummaryDivider