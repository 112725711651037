import {isNullOrUndefined} from "../../../services/Validator";

function LegalsLayout({children}) {
    // Used in both Legals and Privacy screens to frame
    // content in both tablet and desktop modes

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-auto bg-backgroundLight3 w-full grow flex flex-col justify-start items-center"}>
            {children}
        </div>
    );
}

// EXPORT
export default LegalsLayout