

// METHODS
// ---- Render about app bar text color
export const renderAboutBarTextColor = (isSelected, isHovered) => {
    if (isSelected) {
        return 'text-greyLight11'
    } else {
        return isHovered ? 'text-greyLight9' : 'text-greyLight7'
    }
}