import {generatePath} from "react-router";
import {api} from "./routes";
import {makeRequest, Methods} from "./request";

// List of methods triggering Knowledge related api calls

// ---- Get themes
export function get_knowledges_for_grade(id) {
    const url = api.domain + generatePath(api.routes.knowledgesForGrade, {id})
    return makeRequest(url, Methods.GET, null)
}