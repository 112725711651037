import {useEffect, useState} from "react";
import MobileRedirectPhoneIcon from "../components/icons/mobileRedirectScreen/MobileRedirectPhoneIcon";
import SystemScreenInfoTitleText from "../components/texts/SystemScreenInfoTitleText";
import {kMobileRedirectTexts} from "../constants/texts";
import MobileRedirectAppStoreLinkImage from "../components/images/mobileRedirectScreen/MobileRedirectAppStoreLinkImage";
import MobileRedirectPlayStoreLinkImage
    from "../components/images/mobileRedirectScreen/MobileRedirectPlayStoreLinkImage";
import SystemScreenInfoSubtitleText from "../components/texts/SystemScreenInfoSubtitleText";
import {LogoTextSize} from "../controllers/SystemUmmiaLogoTextController";
import SystemUmmiaLogoTextImage from "../components/images/SystemUmmiaLogoTextImage";

function MobileRedirectScreen() {
    // Mobile Screen used in index.js to display message and mobile
    // app links

    // PROPERTIES
    // const isMobile = window.innerWidth <= 640;
    const [isMobile, setIsMobile] = useState(false);

    // HOOKS
    // ---- Add screen resize listener
    useEffect(() => {
        setIsMobileScreen();
        window.addEventListener("resize", setIsMobileScreen);
        return () => window.removeEventListener("resize", setIsMobileScreen);
    }, []);

    // BUILD
    if (!isMobile)
        return null
    return(
        <div className={"animate-appearSlow overflow-auto w-full h-[100svh] flex flex-col justify-start items-center bg-backgroundLight3"}>
            <div className={"w-full h-[56px] min-h-[56px] px-16 flex justify-start items-center"}>
                <SystemUmmiaLogoTextImage logoTextSize={LogoTextSize.XSMALL} />
            </div>
            <div className={"grow flex flex-col justify-center px-24 pb-64"}>
                <div className={"flex flex-col justify-center items-center space-y-16 p-24"}>
                    <MobileRedirectPhoneIcon />
                    <div className={"flex flex-col space-y-6 justify-center items-center"}>
                        <SystemScreenInfoTitleText title={kMobileRedirectTexts.infoTitle} />
                        <SystemScreenInfoSubtitleText subtitle={kMobileRedirectTexts.infoSubtitle} />
                    </div>
                </div>
                <div className={"flex flex-col justify-center items-center space-y-16"}>
                    <MobileRedirectPlayStoreLinkImage />
                    <MobileRedirectAppStoreLinkImage />
                </div>
            </div>
        </div>
    );

    // METHODS
    // ---- Set is mobile with current screen innerWidth
    function setIsMobileScreen() {
        setIsMobile(window.innerWidth <= 640)
    }

}

// EXPORT
export default MobileRedirectScreen