
function ResultsScoreCardContentLayout({children}) {
    // Used in ResultsScoreCard to frame score content layout
    // in desktop mode

    // BUILD
    return (
        <div className={"w-full grow flex justify-around px-16"}>
            {children}
        </div>
    );
}

// EXPORT
export default ResultsScoreCardContentLayout