import ResultsCardHeaderDivider from "../../../dividers/flashScreens/resultsStepScreen/ResultsCardHeaderDivider";
import ResultsQuestionsList from "../../../lists/flashScreens/resultsStepScreen/ResultsQuestionsList";
import ResultsCardHeader from "../../../others/flashScreens/resultsStepScreen/ResultsCardHeader";


function ResultsDesktopQuestionsCard({questions}) {
    // Used in ResultsStepScreen to display Questions List Card
    // in desktop mode

    // BUILD
    return (
        <div className={"overflow-auto bg-backgroundLight5 hidden h-[544px] min-h-[544px] max-h-[544px] w-[536px] max-w-[536px] lg:flex lg:flex-col shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px]"}>
            {/** CORE */}
            <ResultsCardHeader headerTitle={`Liste des questions (${questions?.length})`} />
            <ResultsCardHeaderDivider />
            <div className={"overflow-auto bg-backgroundLight5 w-full grow shadow-[inset_0px_0px_2px_rgba(82,96,120,1)]"}>
                <ResultsQuestionsList questions={questions} />
            </div>
        </div>
    );
}

// EXPORT
export default ResultsDesktopQuestionsCard