import SystemOptionsCardHeader from "../others/SystemOptionsCardHeader";
import {useState} from "react";
import SystemOptionContentLayout from "../layouts/SystemOptionContentLayout";
import SystemOptionTopContentLayout from "../layouts/SystemOptionTopContentLayout";
import SystemOptionProposalText from "../texts/SystemOptionProposalText";
import SystemOptionJudgementText from "../texts/SystemOptionJudgementText";
import SystemExplanationsDivider from "../dividers/SystemExplanationsDivider";
import SystemOptionBottomContentLayout from "../layouts/SystemOptionBottomContentLayout";
import SystemOptionExplanationText from "../texts/SystemOptionExplanationText";
import {isNullOrUndefined} from "../../services/Validator";
import {get_nested} from "../../services/JsonHandler";
import {getCorrectOptionIndex, getLandingOptionIndex} from "../../controllers/SystemOptionsCardController";
import {kDontKnowOption} from "../../constants/objects";

function SystemOptionsCard({question, extraClass}) {
    // Used through the app to display single Question
    // Options list with corresponding explanations

    // PROPERTIES
    const correctOptionIndex = getCorrectOptionIndex(question)
    const selectedOption = get_nested(['selectedOption', 'proposal'], question, null)
    const noAnswerWasSubmitted = (isNullOrUndefined(selectedOption) || selectedOption === kDontKnowOption.proposal)
    const landingOptionIndex = getLandingOptionIndex(question)
    const [selectedTab, setSelectedTab] = useState(landingOptionIndex)
    // ---- Current displayed Option : kDontKnowOption redirected to correct Option
    const displayedOption = question?.options[selectedTab]
    const displayedOptionExplanation = get_nested(['explanation'], displayedOption, null)

    // BUILD
    if (isNullOrUndefined(question))
        return null
    return (
        <div className={"overflow-hidden sm:overflow-auto w-full min-h-[264px] bg-backgroundLight5 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px] flex flex-col " + extraClass}>
            {/** HEADER */}
            <SystemOptionsCardHeader correctOptionIndex={correctOptionIndex}
                                     selectedTab={selectedTab}
                                     setSelectedTab={setSelectedTab} />
            {/** CONTENT */}
            <SystemOptionContentLayout>
                {/** ---- TOP PART */}
                <SystemOptionTopContentLayout>
                    <SystemOptionProposalText option={displayedOption} />
                    <SystemOptionJudgementText option={displayedOption}
                                               noAnswerWasSubmitted={noAnswerWasSubmitted}
                                               isSelectedAnswer={!isNullOrUndefined(landingOptionIndex) ? landingOptionIndex === selectedTab : null} />
                </SystemOptionTopContentLayout>
                {/** ---- DIVIDER */}
                <SystemExplanationsDivider />
                {/** ---- BOTTOM PART */}
                <SystemOptionBottomContentLayout>
                    <SystemOptionExplanationText explanationText={displayedOptionExplanation} />
                </SystemOptionBottomContentLayout>
            </SystemOptionContentLayout>
        </div>
    );
}

// EXPORT
export default SystemOptionsCard