import {CircularProgress} from "@mui/material";

function ParameterSubthemesLoader() {
    // Used in ParameterSubthemesContent to display
    // loader inside rounded container

    // BUILD
    return (
        <div className={"animate-appear w-full h-full flex flex-col justify-center items-center shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px] mt-24"}>
            <CircularProgress size={"28px"}
                              style={{'color' : 'white'}} />
        </div>
    );
}

// EXPORT
export default ParameterSubthemesLoader