import {Pie} from "react-chartjs-2";
import {ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title} from "chart.js";

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend
)

function ResultsPieChart({scores}) {
    // Used in ResultsGraphList to display flash results with
    // good, bad, and no answered scores

    // PROPERTIES
    // ---- Pie chart parameters : score distribution
    const pieData = {
        datasets: [
            {
                data: scores,
                backgroundColor: [
                    '#1693E3',
                    '#DAAD55',
                    '#8291A9',
                ],
                hoverBackgroundColor: [
                    '#1693E3',
                    '#DAAD55',
                    '#8291A9',
                ],
                borderWidth: 0,
            },
        ],
    };

    // BUILD
    return (
        <div className={"w-full h-[200px] min-h-[200px] sm:h-[124px] sm:min-h-[124px] lg:h-[176px] lg:min-h-[176px] flex justify-center"}>
            <Pie data={pieData}
                 options={{
                     responsive: true,
                     maintainAspectRatio: true,
                     plugins: {
                         legend: {
                             display: false,
                         },
                     },
                 }}
            />
        </div>
    );
}

// EXPORT
export default ResultsPieChart