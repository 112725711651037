import {useEffect, useState} from "react";
import {isNullOrUndefined} from "../../services/Validator";
import {kSystemIcons} from "../../constants/icons";
import SystemQuestionPictureLoader from "../loaders/SystemQuestionPictureLoader";
import SystemQuestionPictureError from "../errors/SystemQuestionPictureError";
import {
    computePictureUrl,
    computePreferredAxis,
    computeQuestionPictureSize
} from "../../controllers/flashScreens/questionsStepScreen/QuestionPictureController";

function SystemQuestionPicture({pictureUrl, pictureRatio, isForMobile, openZoom, extraClass}) {
    // Used in QuestionsCard to display
    // question related image

    // PROPERTIES
    const [hasError, setHasError] = useState(false);
    const [isHovered, setIsHovered] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [src, setSrc] = useState(null)

    // HOOKS
    useEffect(() => {
        if (!isNullOrUndefined(pictureUrl)) {
            // Switch loader on and clear potential error
            setIsLoading(true)
            setHasError(false)
            // Prepare
            const url = computePictureUrl(pictureUrl)
            // Execute
            fetch(url).then((res) => {
                res.blob().then((res) => {
                    setSrc(URL.createObjectURL(res))
                }).catch((err) => {
                    console.log(err)
                    setHasError(true)
                })
            }).catch((err) => {
                console.log(err)
                setHasError(true)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [pictureUrl])

    // BUILD
    if (isNullOrUndefined(pictureUrl))
        return null
    if (hasError)
        return <SystemQuestionPictureError pictureRatio={pictureRatio} />
    if (isLoading)
        return <SystemQuestionPictureLoader size={computeQuestionPictureSize(pictureRatio, isForMobile)}/>
    return (
        <div className={`animate-appear relative ${!isForMobile ? 'cursor-pointer' : 'cursor-auto'} overflow-hidden rounded-[8px] ${computeQuestionPictureSize(pictureRatio, isForMobile)} ${extraClass}`}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
             onClick={openZoom} >
            <img src={src}
                 alt="Illustration"
                 className={`object-cover ${computePreferredAxis(pictureRatio)}`}
                 onError={() => setHasError(true)}
                 onLoad={() => setHasError(false)} />
            <div className={`animate-appearFast ${(isHovered && !isForMobile) ? 'absolute bottom-0 left-0 w-full h-[32px] bg-gradient-to-t from-backgroundLight1/80 to-transparent opacity-100' : 'opacity-0'}`}></div>
            <div className={`animate-appearFast ${(isHovered && !isForMobile) ? "flex justify-center items-center absolute bottom-[2px] right-[4px]" : "hidden"}`}>
                <i className={`material-icons text-[24px] text-white`}>
                    {kSystemIcons.zoomIn}
                </i>
            </div>
        </div>
    );
}

// EXPORT
export default SystemQuestionPicture