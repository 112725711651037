import {isNullOrUndefined} from "../../../services/Validator";
import {formatIsoDateToDDMMYY} from "../../../helpers/DateTimeHelper";

function LegalsDesktopTabletLastUpdateText({updatedAt}) {
    // Used in LawDesktopTabletTitle to display
    // text date update in desktop tablet mode

    // BUILD
    if (isNullOrUndefined(updatedAt))
        return null
    return (
        <div className={"mt-12"}>
            <span className={"text-[14px] font-normal text-greyLight5"}>
                {`Mise à jour le : ${formatIsoDateToDDMMYY(updatedAt)}`}
            </span>
        </div>
    );
}

// EXPORT
export default LegalsDesktopTabletLastUpdateText