import {useState} from "react";
import {get_nested} from "../../services/JsonHandler";
import {isNullOrUndefined} from "../../services/Validator";
import {
    renderQuestionOptionStyle
} from "../../controllers/flashScreens/questionsStepScreen/QuestionsOptionItemController";


function SystemDraftQuestionOptionItem({option, selectedOption, setSelectedOption}) {
    // Used in SystemDraftQuestionCardOptionsList to display one single option
    // proposal block in desktop mode

    // PROPERTIES
    const[isHovered, setIsHovered] = useState(false)
    const optionProposal = get_nested(['proposal'], option, null)

    // BUILD
    if (isNullOrUndefined(option) || isNullOrUndefined(option?.proposal))
        return null
    return (
        <div className={`flex items-center justify-start px-12 sm:px-16 py-8 sm:py-12 leading-none border-1 rounded-[8px] cursor-pointer ${renderQuestionOptionStyle(option, selectedOption, isHovered)}`}
             onClick={() => setSelectedOption(option)}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)} >
            <span className="font-semibold text-[18px] sm:text-[20px]">
                {optionProposal}
            </span>
        </div>
    );
}

// EXPORT
export default SystemDraftQuestionOptionItem