import QuestionsProgressBar from "./QuestionsProgressBar";
import SystemTabletTopBarLayout from "../../../layouts/SystemTabletTopBarLayout";
import SystemFlashTopBarLogoLayout from "../../../layouts/SystemFlashTopBarLogoLayout";
import SystemFlashLogoImage from "../../../images/SystemFlashLogoImage";
import SystemFlashSelectedGradeBadge from "../../../badges/SystemFlashSelectedGradeBadge";
import {FlashLogoTextSize} from "../../../../controllers/SystemFlashLogoTextController";
import SystemDesktopTabletLeaveFlashButton from "../../../buttons/SystemDesktopTabletLeaveFlashButton";

function QuestionsTabletTopBar({onLeaveFlashPressed,
                                selectedGrade,
                                currentQuestion,
                                totalQuestion}) {
    // Used in QuestionsStepScreen to display top bar
    // in mobile mode

    // BUILD
    return (
        <SystemTabletTopBarLayout>
            <SystemFlashTopBarLogoLayout>
                <SystemFlashLogoImage flashLogoSize={FlashLogoTextSize.XSMALL} />
                <SystemFlashSelectedGradeBadge selectedGrade={selectedGrade} />
            </SystemFlashTopBarLogoLayout>
            <QuestionsProgressBar currentQuestion={currentQuestion}
                                  totalQuestion={totalQuestion} />
            <SystemDesktopTabletLeaveFlashButton onLeaveFlashPressed={onLeaveFlashPressed} />
        </SystemTabletTopBarLayout>
    );
}

// EXPORT
export default QuestionsTabletTopBar