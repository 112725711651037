import {renderAboutBarTextColor} from "../../../controllers/aboutScreen/AboutTabBarController";

function AboutTabletTabBarItemText({text, isSelected, isHovered}) {
    // Used in AboutDesktopTabBarItem to show
    // section title text

    // BUILD
    return (
        <div className={"h-full hidden sm:block whitespace-nowrap"}>
            <span className={`text-[20px] font-medium ${renderAboutBarTextColor(isSelected, isHovered)}`}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default AboutTabletTabBarItemText