import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import QuestionsStepScreen from "./flashScreens/QuestionsStepScreen";
import ResultsStepScreen from "./flashScreens/ResultsStepScreen";
import {getLocalItemAsJsonFromKey, setLocalItemFromKey} from "../services/LocalStorage";
import {kSystemKeys} from "../constants/keys";
import {isArrayNullUndefinedOrEmpty, isNullOrUndefined} from "../services/Validator";
import ParameterStepScreen from "./flashScreens/ParameterStepScreen";
import {recordPageViewMetric} from "../controllers/SystemPageViewMetricController";
import {EventName} from "../enums/PageViewMetric";
import {routes} from "../constants/routes";
import {useResizeListener} from "../listeners/SystemResizeListener";

function FlashScreen() {
    // Used in Router to manage Flash user flow

    // PROPERTIES
    // ---- Navigation
    let navigate = useNavigate();
    const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false)
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
    // ---- Functional
    const [flashMetaStep, setFlashMetaStep] = useState(0)
    const [selectedGrade, setSelectedGrade] = useState(null)
    const [flashQuestions, setFlashQuestions] = useState(null)
    const [questionIndex, setQuestionIndex] = useState(0)
    const [selectedOption, setSelectedOption] = useState(null)

    // HOOKS
    // ---- Eventually close modal during screen resize
    useResizeListener(() => setIsLeaveModalOpen(false))

    // ---- At rendering : if local flash is in storage, set flash variables
    useEffect(() => {
        if (!isNullOrUndefined(localStorage.getItem(kSystemKeys.flash))) {
            // Get ongoing flash in local storage
            const localFlash = getLocalItemAsJsonFromKey(kSystemKeys.flash);
            if (!isNullOrUndefined(localFlash.grade) && !isNullOrUndefined(localFlash.questions) && !isNullOrUndefined(localFlash.currentQuestion) && !isNullOrUndefined(localFlash.metaStep)) {
                // STATE + LOCAL : Set flash level and rules
                setFlashGrade(localFlash.grade)
                // STATE ONLY : Set questions, currentQuestion, flashMetaStep, and existing flash snackbar
                setFlashQuestions(localFlash.questions)
                setQuestionIndex(localFlash.currentQuestion)
                setFlashMetaStep(localFlash.metaStep)
                setIsSnackbarOpen(true)
            }
        }
    }, []);

    // BUILD
    switch (flashMetaStep) {
        case 0:
            return <ParameterStepScreen selectedGrade={selectedGrade}
                                        setFlashGrade={setFlashGrade}
                                        fetchQuestionsCallback={fetchQuestionsCallback} />
        case 1:
            return <QuestionsStepScreen selectedGrade={selectedGrade}
                                        questions={flashQuestions}
                                        questionIndex={questionIndex}
                                        selectedOption={selectedOption}
                                        setSelectedOption={setSelectedOption}
                                        submitAnswer={submitQuestionAnswer}
                                        isLeaveModalOpen={isLeaveModalOpen}
                                        setIsLeaveModalOpen={setIsLeaveModalOpen}
                                        onLeaveFlashPressed={onLeaveFLashPressed}
                                        leaveFlash={leaveFlash}
                                        isSnackbarOpen={isSnackbarOpen}
                                        handleClose={handleClose} />
        case 2:
            return <ResultsStepScreen selectedGrade={selectedGrade}
                                      questions={flashQuestions}
                                      isLeaveModalOpen={isLeaveModalOpen}
                                      setIsLeaveModalOpen={setIsLeaveModalOpen}
                                      onLeaveFlashPressed={onLeaveFLashPressed}
                                      leaveFlash={leaveFlash}
                                      isSnackbarOpen={isSnackbarOpen}
                                      handleClose={handleClose}/>
        default:
            return <ParameterStepScreen selectedGrade={selectedGrade}
                                        setFlashGrade={setFlashGrade}
                                        fetchQuestionsCallback={fetchQuestionsCallback} />
    }

    // METHODS
    // ---- ---- ---- ----
    // NAVIGATION
    // ---- ---- ---- ----
    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbarOpen(false);
    }

    // ---- Leave flash
    function leaveFlash() {
        // LOCAL : Clear local storage
        localStorage.clear()
        // NAV : Go home
        navigate(routes.home)
    }

    // ---- On leave flash pressed
    function onLeaveFLashPressed() {
        if (flashMetaStep >=1) {
            // Case : flash screen => ask for confirmation
            setIsLeaveModalOpen(true)
        } else {
            // Case : level or rules step screen => leaveFlash
            leaveFlash()
        }
    }

    // ---- ---- ---- ----
    // PARAMETER STEP SCREEN
    // ---- ---- ---- ----
    // ---- Set flash level in state and local
    function setFlashGrade(selectedGrade) {
        // STATE : Set selected level
        setSelectedGrade(selectedGrade)
        // LOCAL STORAGE : Get local flash, update and set local flash
        const localFlash = getLocalItemAsJsonFromKey(kSystemKeys.flash)
        setLocalItemFromKey(kSystemKeys.flash, {...localFlash, grade: selectedGrade})
    }

    // ---- Flash questions callback (after api call)
    function fetchQuestionsCallback(fetchedQuestions) {
        // Defensive measure
        if (isArrayNullUndefinedOrEmpty(fetchedQuestions))
            return
        // STATE : Set questions and flashMetaStep
        setFlashQuestions(fetchedQuestions)
        const newFlashMetaStep = 1
        setFlashMetaStep(newFlashMetaStep)
        // LOCAL STORAGE : Store questions, currentQuestion and metaStep
        const localFlash = getLocalItemAsJsonFromKey(kSystemKeys.flash)
        setLocalItemFromKey(kSystemKeys.flash, {...localFlash, questions: fetchedQuestions, currentQuestion: 0, metaStep: newFlashMetaStep})
        // METRIC : Record launch flash metric
        recordPageViewMetric(EventName.FLASH_LAUNCH)
    }

    // ---- ---- ---- ----
    // QUESTIONS STEP SCREEN
    // ---- ---- ---- ----
    // ---- Save answer & eventually go to correction
    function submitQuestionAnswer() {
        // Get local flash
        let localFlash = getLocalItemAsJsonFromKey(kSystemKeys.flash)
        // Destructuring question and adding selected answer
        flashQuestions[questionIndex] = {...flashQuestions[questionIndex], selectedOption: selectedOption}
        // Add selectedOption in localFlash
        localFlash = {...localFlash, questions: flashQuestions}
        // Emptying selected option var
        setSelectedOption(null)
        // Making flash go forward : next question or correction depending on index
        if (questionIndex === flashQuestions.length - 1) {
            // Case : last question
            // ---- Update state
            const newFlashMetaStep = flashMetaStep + 1
            setFlashMetaStep(flashMetaStep + 1)
            // ---- Update local storage
            localFlash.metaStep = newFlashMetaStep
        } else {
            // Case : not last question
            setQuestionIndex(questionIndex + 1)
            localFlash.currentQuestion = questionIndex + 1
        }
        // LOCAL STORAGE : Update local flash
        setLocalItemFromKey(kSystemKeys.flash, localFlash)
    }
}

// EXPORT
export default FlashScreen