import {SystemPrimaryCustomButton, SystemSecondaryCustomButton} from "./SystemCustomButton";
import {kButtonStyles} from "../../constants/styles";

function SystemScreenErrorActionButtons({onSecondaryPressed, secondaryLabel, onPrimaryPressed, primaryLabel}) {
    // Used through the app to display two action buttons
    // in error screen in both mobile and desktop mode

    // BUILD
    return (
        <div className={"w-full flex space-x-12 sm:space-x-24"}>
            <SystemSecondaryCustomButton
                label={secondaryLabel}
                isDisabled={false}
                isLoading={false}
                isFullWidth={true}
                sizeStyle={kButtonStyles.mediumButtonStyle}
                onButtonClick={() => onSecondaryPressed()} />
            <SystemPrimaryCustomButton
                label={primaryLabel}
                isDisabled={false}
                isLoading={false}
                isFullWidth={true}
                onButtonClick={() => onPrimaryPressed()}
                sizeStyle={kButtonStyles.mediumButtonStyle} />
        </div>
    );
}

// EXPORT
export default SystemScreenErrorActionButtons