import {kSystemIcons} from "../constants/icons";
import {kKnowledgesTexts} from "../constants/texts";
import {isNullOrUndefined} from "../services/Validator";

// METHODS
// ---- Interpret Knowledge type from api to name and icon
export function renderKnowledgeType(knowledgeType, amount) {
    if (knowledgeType === 'Definition') {
        return {
            title: kKnowledgesTexts.definitionType + `${(!isNullOrUndefined(amount) && amount > 1) ? "s" : ""}`,
            icon: kSystemIcons.heart
        }
    } else if (knowledgeType === 'Convention') {
        return {
            title: kKnowledgesTexts.conventionType + `${(!isNullOrUndefined(amount) && amount > 1) ? "s" : ""}`,
            icon: kSystemIcons.convention
        }
    } else if (knowledgeType === 'Method') {
        return {
            title: kKnowledgesTexts.methodType + `${(!isNullOrUndefined(amount) && amount > 1) ? "s" : ""}`,
            icon: kSystemIcons.steps
        }
    } else if (knowledgeType === 'Formula') {
        return {
            title: kKnowledgesTexts.formulaType + `${(!isNullOrUndefined(amount) && amount > 1) ? "s" : ""}`,
            icon: kSystemIcons.function
        }
    } else if (knowledgeType === 'Theorem') {
        return {
            title: kKnowledgesTexts.theoremType + `${(!isNullOrUndefined(amount) && amount > 1) ? "s" : ""}`,
            icon: kSystemIcons.title
        }
    } else if (knowledgeType === 'Property') {
        return {
            title: kKnowledgesTexts.propertyType + `${(!isNullOrUndefined(amount) && amount > 1) ? "s" : ""}`,
            icon: kSystemIcons.property
        }
    } else {
        return {
            title: kKnowledgesTexts.propertyType,
            icon: kSystemIcons.question
        }
    }
}