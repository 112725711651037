import {isNullOrUndefined} from "../../../../services/Validator";

function QuestionsMobileQuestionImageLayout({children}) {
    // Used in PreviewMobileQuestionsCard

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"animate-appear w-full flex justify-center"}>
            {children}
        </div>
    );
}

// EXPORT
export default QuestionsMobileQuestionImageLayout