import {kHomeTexts} from "../../../constants/texts";

function HomeSubtitleText() {
    // Used in HowShowCase to display subtitle
    // text

    // BUILD
    return (
        <div className={"w-full text-center px-16"}>
            <span className={"text-greyLight11 font-medium text-[20px] md:text-[24px]"}>
               {kHomeTexts.subPunchline}
            </span>
        </div>
    );
}

// EXPORT
export default HomeSubtitleText