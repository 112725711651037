import {isNullOrUndefined} from "../../services/Validator";

function SystemFlashTopBarLogoLayout({children}) {
    // Used in

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"flex justify-start items-center space-x-8 lg:space-x-12"}>
            {children}
        </div>
    );
}

// EXPORT
export default SystemFlashTopBarLogoLayout