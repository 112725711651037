import {useNavigate} from "react-router-dom";
import {routes} from "../../constants/routes";
import ParameterDesktopTopBar from "../../components/bars/flashScreens/parameterStepScreen/ParameterDesktopTopBar";
import ParameterTabletTopBar from "../../components/bars/flashScreens/parameterStepScreen/ParameterTabletTopBar";
import ParameterContent from "../../components/others/flashScreens/parameterStepScreen/ParameterContent";
import ParameterLayout from "../../components/layouts/flashScreens/parameterStepScreen/ParameterLayout";
import ParameterContentLayout from "../../components/layouts/flashScreens/parameterStepScreen/ParameterContentLayout";

function ParameterStepScreen({selectedGrade, setFlashGrade, fetchQuestionsCallback}) {
    // Used in FlashScreen for the user to select LevelAs through
    // Subthemes

    // PROPERTIES
    let navigate = useNavigate();

    // BUILD
    return (
        <div className="animate-appear overflow-auto bg-backgroundLight3 w-full h-[100svh] flex flex-col items-center justify-start">
            <ParameterTabletTopBar goBackToHome={() => navigate(routes.home)} />
            <ParameterDesktopTopBar goBackToHome={() => navigate(routes.home)} />
            <ParameterLayout>
                <ParameterContentLayout>
                    <ParameterContent setFlashGrade={setFlashGrade}
                                      selectedGrade={selectedGrade}
                                      fetchQuestionsCallback={fetchQuestionsCallback} />
                </ParameterContentLayout>
            </ParameterLayout>
        </div>
    );
}

// EXPORT
export default ParameterStepScreen