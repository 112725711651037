import {kSystemImages} from "../../../constants/images";

function ErrorScreenImage() {
    // Used in ErrorScreen to display a full screen
    // image for not found routes

    // BUILD
    return (
        <div className={`rounded-[16px] overflow-hidden w-full h-[400px] min-h-[360px] sm:w-[600px] sm:h-full sm:min-h-[400px] lg:w-[900px] lg:min-h-[560px]`}>
            <img className={`object-cover h-full`}
                 src={kSystemImages.routeNotFound}
                 alt="Page non trouvée" />
        </div>
    );
}

// EXPORT
export default ErrorScreenImage