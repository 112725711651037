import {isNullOrUndefined} from "../../../services/Validator";

function ThemesContentLayout({children}) {
    // Used in ThemesContent to frame Themes
    // content made of intro text and Themes list

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"animate-appearSlow w-[560px] md:w-[640px] lg:w-[720px] grow flex flex-col justify-start items-start space-y-24 py-24 lg:py-32"}>
            {children}
        </div>
    );
}

// EXPORT
export default ThemesContentLayout