
function ResultsDetailedScoreItemText({label, isGoodScore}) {
    // Used in ResultsDetailedScoreItem to show answers
    // type counter label

    // BUILD
    if (!label)
        return null;
    return (
        <div className={"leading-tight sm:leading-normal"}>
            <span className={`${isGoodScore ? "text-white" : "text-greyLight9"} font-medium text-[16px]`}>
                {label}
            </span>
        </div>
    );
}

// EXPORT
export default ResultsDetailedScoreItemText