
// Used through the app to handle logo text component controllers

// PROPERTIES
export const FlashLogoTextSize = {
    XXSMALL: "xxsmall",
    XSMALL: "xsmall",
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large"
}

// METHODS
// ---- Render logo text image size
export function renderFlashLogoTextSize(flashLogoTextSize) {
    switch(flashLogoTextSize) {
        case FlashLogoTextSize.XXSMALL:
            return "h-[16px] min-h-[16px] max-h-[16px] w-[64px] min-w-[64px] max-w-[64px]"
        case FlashLogoTextSize.XSMALL:
            return "h-[24px] min-h-[24px] max-h-[24px] w-[95px] min-w-[95px] max-w-[95px]"
        case FlashLogoTextSize.SMALL:
            return "h-[32px] min-h-[32px] max-h-[32px] w-[127px] min-w-[127px] max-w-[127px]"
        case FlashLogoTextSize.MEDIUM:
            return "h-[40px] min-h-[40px] max-h-[40px] w-[159px] min-w-[159px] max-w-[159px]"
        case FlashLogoTextSize.LARGE:
            return "h-[48px] min-h-[48px] max-h-[48px] w-[190px] min-w-[190px] max-w-[190px]"
        default:
            return "h-[32px] min-h-[32px] max-h-[32px] w-[127px] min-w-[127px] max-w-[127px]"
    }
}