import SystemScreenErrorTitleText from "./SystemScreenErrorTitleText";
import SystemScreenErrorSubtitleText from "./SystemScreenErrorSubtitleText";
import {isNullOrUndefined} from "../../services/Validator";


function SystemScreenErrorTexts({title, subtitle}) {
    // Used through the app to frame screen error title and subtitle
    // in both desktop and mobile mode

    // BUILD
    if (isNullOrUndefined(title) && isNullOrUndefined(subtitle))
        return null;
    return (
        <div className={"w-full flex flex-col justify-start items-center space-y-8"}>
            <SystemScreenErrorTitleText text={title} />
            <SystemScreenErrorSubtitleText text={subtitle} />
        </div>
    );
}

// EXPORT
export default SystemScreenErrorTexts